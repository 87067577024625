<div or-login>
    <header>
        <div class="row">
            <div>
                <div>
                    <!--h1>CAS'APPS</h1-->
                    <img alt="{{ 'login.logoAlt' | translate }}" src="./assets/img/login_app/casapps-blanc.png" />
                </div>
            </div>
        </div>
    </header>
    <nav>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
            <!--div class="row">
                <div>
                    <div>
                        <input my-formControl type="text" value="{{ 'login.entity' | translate }}" name="loginEntity" formControlName="entity" onfocus="value=''" />
                    </div>
                </div>
            </div-->

            <div class="row">
                <div>
                    <label style="font-weight: bold; color: whitesmoke;">{{ 'login.login' | translate }}</label>
                    <div>
                        <input my-formControl type="text"
                            name="loginLogin" formControlName="login" class="SafariFix" type="email" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    <label style="font-weight: bold; color: whitesmoke;">{{ 'login.password' | translate }}</label>
                    <div>
                        <input my-formControl type="password"
                            name="loginPassword" formControlName="password" onfocus="value='';type='password'"
                            class="SafariFix" />
                    </div>
                </div>
            </div>
            <div *ngIf=loginFlag>
                <p [ngStyle]="{'font-weight': 'bold','text-align': 'center','margin-bottom': '0rem'}">{{errorLoginMsg}}
                </p>
            </div>
            <div class="row">
                <div>
                    <button type="submit" id="button-connexion">
                        {{ 'login.connection' | translate }}
                    </button>
                </div>
                
            </div>
            <input my-formControl type="hidden" value="CASA_WEB" name="loginUserDomain" formControlName="userDomain"
                onfocus="value=''" />
            <input my-formControl type="hidden" value="12345" name="loginUuid" formControlName="uuid"
                onfocus="value=''" />
            <input my-formControl type="hidden" value="" name="loginLanguage" formControlName="loginLanguage"
                onfocus="value=''" />
        </form>
    </nav>
    <div #loading class="text-center">
        <div class="spinner-border" style="color: white;">
        </div>
    </div>
    <section>
        <aside>
            <div class="row">
                <div>
                    <div>
                        <div #comboBox>
                            <div #listComboBox>
                                <div #btEn_en>
                                    <p>
                                        <img #cocheEn src="./assets/img/login_app/coche.svg" alt="check" />
                                        <img src="./assets/img/login_app/en_flag.svg" alt="english" />
                                        {{ nameLocaleEn }}
                                    </p>
                                </div>
                                <div #btFr_fr>
                                    <p>
                                        <img #cocheFr src="./assets/img/login_app/coche.svg" alt="check" />
                                        <img src="./assets/img/login_app/fr_flag.svg" alt="french" />
                                        {{ nameLocaleFr }}
                                    </p>
                                </div>
                            </div>
                            <img #chevronComboBox src="./assets/img/login_app/chevron-down.svg" alt="stripe" />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    </section>
    <footer>
        <or-footer-login>
        </or-footer-login>
    </footer>
</div>