export class TagEntity{

    private _name:string;

    constructor(appParams:any){
        this._name = appParams.tagName;
    }

    public get name():string{
        return this._name;
    }

}