<div or-list>
    <header>
        <or-header [_isWithBurger]=true [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <article>
            <div class="row">
                <div>
                    <div>
                        <h2>{{ 'appList.discover' | translate }}</h2>
                    </div>
                    <div>
                        <p>{{ date | localeDate:'EEEE dd LLLL yyyy' }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    <or-carousel [pageCaseService]=0 [numMinIMG]=1 [idPageFiche]="null"></or-carousel>
                </div>
            </div>
            <aside>
                <div class="row">
                    <div>
                        <h3>{{ 'appList.appInt' | translate }}</h3>
                    </div>
                    <div>
                        <div *ngFor="let appEntity of listAppInt">
                            <or-app [appEntity]="appEntity"></or-app>
                        </div>
                    </div>
                </div>
            </aside>
            <aside>
                <div class="row">
                    <div>
                        <h3>{{ 'appList.appTest' | translate }}</h3>
                    </div>
                    <div>
                        <div *ngFor="let appEntity of listAppTest">
                            <or-app [appEntity]="appEntity">
                            </or-app>
                        </div>
                    </div>
                </div>
            </aside>
        </article>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>