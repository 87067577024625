import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy, AfterViewChecked } from "@angular/core";
import { AppEntity } from 'src/app/entities/app.entity';
import { RouteService } from 'src/app/service/route.service';

@Component({
    selector: "recommended-root",
    templateUrl: "./recommended.component.html",
    styleUrls: ["./recommended.component.scss"]
})
export class RecommendedComponent implements OnInit, AfterViewInit, OnDestroy{

    @Input() appEntity:AppEntity;
    @ViewChild('btApp') btApp:ElementRef;
    @ViewChild('icon') icon:ElementRef;

    private _callGotoFicheApp:Function;
    private _name:string;
    private _iconeAppUrl:string;
    private _timerImgLoad:any;

    public get name():string{
        return this._name;
    }

    public get iconeAppUrl():string{
        return this._iconeAppUrl;
    }

    constructor(private _routeService:RouteService){

    }

    public ngOnInit():void{
        this._name = this.appEntity.name;
        this._iconeAppUrl = this.appEntity.urlIcone;

        this._callGotoFicheApp = (e:MouseEvent) => { this.gotoFicheAppHandler(e) };
        this._timerImgLoad = setInterval(() => { this.updateWithImg()}, 20);
    }

    public ngAfterViewInit():void{
        this.btApp.nativeElement.addEventListener('click', this._callGotoFicheApp);
        this.btApp.nativeElement.style.margin = 'auto';
    }

    public ngOnDestroy():void{
        this.btApp.nativeElement.removeEventListener('click', this._callGotoFicheApp);
        clearInterval(this._timerImgLoad);
    }

    private updateWithImg():void{
        let styleIconImg:CSSStyleDeclaration = window.getComputedStyle(this.icon.nativeElement);
        if(parseInt(styleIconImg.width) > 0){
            this.btApp.nativeElement.style.width = styleIconImg.width;
            clearInterval(this._timerImgLoad);
        }
    }

    private gotoFicheAppHandler(e:MouseEvent):void{
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_FICHE_APP + "/" + this.appEntity.id);
    }

}