import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class I18nService{

    public static readonly NAME_LOCALE_FR:string = 'Français';
    public static readonly NAME_LOCALE_EN:string = 'English';
    public static readonly LOCALE_EN:string = 'en';
    public static readonly LOCALE_FR:string = 'fr';
    public static CURRENT_LOCALE:string = 'fr';

    public localeChange:EventEmitter<any>;

    constructor(){
        this.localeChange = new EventEmitter<any>();
    }

}