import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AboutPage } from './page/about/about.page';
import { AppComponent } from './page/component/app/app.component';
import { CarouselComponent } from './page/component/carouselComponent/carousel.component';
import { ImgCarouselComponent } from './page/component/carouselComponent/item/img.item.component';
import { FooterAppComponent } from './page/component/footerComponent/footerApp/footerApp.component';
import { FooterLoginComponent } from './page/component/footerComponent/footerLogin/footerLogin.component';
import { HeaderComponent } from './page/component/header/header.component';
import { NavComponent } from './page/component/navComponent/nav.component';
import { RecommendedComponent } from './page/component/recommended/recommended.component';
import { TabContentComponent } from './page/component/tabComponent/tabContent/tabContent.component';
import { TabQuestionComponent } from './page/component/tabComponent/tabQuestion/tabQuestion.component';
import { TabTitleComponent } from './page/component/tabComponent/tabTitle/tabTitle.component';
import { TagComponent } from './page/component/tag/tag.component';
import { FaqPage } from './page/faq/faq.page';
import { AppSheetPage } from './page/app-sheet/app-sheet.page';
import { AppListPage } from './page/app-list/app-list.page';
import { ListTestAppPage } from './page/test-app-list/test-app-list.page';
import { LoginPage } from './page/login/login.page';
import { SearchPage } from './page/search/search.page';
import { AppRoutingModule } from './routing/app-routing.module';
import { ActionService } from './service/action.service';
import { ConnectService } from './service/connect.service';
import { I18nService } from './service/i18n.service';
import { LocaleService } from './service/locale.service';
import { RouteService } from './service/route.service';
import { TweenService } from './service/tween.service';
import { StructureComponent } from './structure/structure.component';
import { LocaleDatePipe } from './utils/locale.date.pipe';
import { NativeElementInjectorDirective } from './utils/nativeElement.injector.directive';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StorageService } from './service/storage.service';
import { AngularDeviceInformationService } from 'angular-device-information';
import { TokenInterceptor } from './core/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { FicheAppTestPage } from './page/component/ficheAppTest/ficheAppTest.page';

@NgModule({
  declarations: [
    NativeElementInjectorDirective,
    LocaleDatePipe,
    StructureComponent,
    NavComponent,
    HeaderComponent,
    FooterLoginComponent,
    FooterAppComponent,
    CarouselComponent,
    ImgCarouselComponent,
    AppComponent,
    TagComponent,
    RecommendedComponent,
    TabTitleComponent,
    TabContentComponent,
    TabQuestionComponent,
    LoginPage,
    AppListPage,
    ListTestAppPage,
    FaqPage,
    AppSheetPage,
    FicheAppTestPage,
    AboutPage,
    SearchPage
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    RouteService,
    I18nService,
    TweenService,
    ActionService,
    LocaleService,
    ConnectService,
    StorageService,
    AngularDeviceInformationService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [StructureComponent]
})
export class AppModule {

 /* constructor(push: SwPush) {
    registerLocaleData(localeFr);

    push.messages.subscribe(msg => {
      const strmsg = msg.payload;
      //console.log(msg);
      //window.alert(JSON.stringify(msg));
      
    })
  }*/
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}