import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import * as TWEEN from '@tweenjs/tween.js';
import { QuestionEntity } from 'src/app/entities/question.entity';
import { ActionService } from 'src/app/service/action.service';
import { TweenService } from 'src/app/service/tween.service';

@Component({
    selector: 'tabQuestion-root',
    templateUrl: './tabQuestion.component.html',
    styleUrls: ['./tabQuestion.component.scss']
})
export class TabQuestionComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() questionEntity: QuestionEntity;
    @ViewChild('content') content: ElementRef;
    @ViewChild('titleContent') titleContent: ElementRef;
    @ViewChild('textContent') textContent: ElementRef;
    @ViewChild('chevronQuestion') chevronQuestion: ElementRef;

    private _title: string;
    private _text: string;
    private _isWithBorderBottom: boolean;
    private _isOpen: boolean;
    private _isTweenRuning: boolean;
    private _titleHeight: number;
    private _textHeight: number;
    private _callClickQuestion: Function;
    private _subQuestionClick: Subscription;
    private _subResize: Subscription;

    public get title(): string {
        return this._title;
    }

    public get text(): string {
        return this._text;
    }

    public get isWithBorderBottom(): boolean {
        return this._isWithBorderBottom;
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    constructor(private _actionService: ActionService,
        private _tweenService: TweenService) {

    }

    public ngOnInit(): void {
        this._title = this.questionEntity.title;
        this._text = this.questionEntity.answer;
        this._isWithBorderBottom = (this.questionEntity.id + 1) < this.questionEntity.totalList;

        this._callClickQuestion = (e: MouseEvent) => { this.toggleQuestionHandler(e) };
        this._subQuestionClick = this._actionService.clickFAQQuestion.subscribe((targetIdentity: any) => { this.openCloseHandler(targetIdentity) });
        this._subResize = this._actionService.resizeEvent.subscribe((event: UIEvent) => { this.resizeHandler(event) });
    }

    public ngAfterViewInit(): void {
        this.resizeHandler(null);

        this.content.nativeElement.addEventListener('click', this._callClickQuestion);
    }

    public ngAfterViewChecked(): void {
        if (!this._isTweenRuning) {
            this.resizeHandler(null);
        }
    }

    public ngOnDestroy(): void {
        this.content.nativeElement.removeEventListener('click', this._callClickQuestion);
        this._subQuestionClick.unsubscribe();
        this._subResize.unsubscribe();
    }

    private computeHeight(): void {
        let stylesTitleContent: CSSStyleDeclaration = window.getComputedStyle(this.titleContent.nativeElement);
        this._titleHeight = parseInt(stylesTitleContent.height) + 7;
        let stylesTextContent: CSSStyleDeclaration = window.getComputedStyle(this.textContent.nativeElement);
        this._textHeight = parseInt(stylesTextContent.height) + 10;
    }

    private toggleQuestionHandler(e: MouseEvent): void {
        this._actionService.clickFAQQuestion.emit({ "idList": this.questionEntity.idList, "idQuestion": this.questionEntity.id });
    }

    private openCloseHandler(targetIdentity: any): void {
        this.computeHeight();
        let objectParams: any;
        if (targetIdentity.idList == this.questionEntity.idList && targetIdentity.idQuestion == this.questionEntity.id) {
            objectParams = {};
            if (this._isOpen) {
                objectParams.startHeight = this._titleHeight + this._textHeight;
                objectParams.heightTarget = this._titleHeight;
                objectParams.startRotate = 180;
                objectParams.rotateTarget = 0;
            } else {
                objectParams.startHeight = this._titleHeight;
                objectParams.heightTarget = this._titleHeight + this._textHeight;
                objectParams.startRotate = 0;
                objectParams.rotateTarget = 180;
            }
            this._isOpen = !this._isOpen;
        } else {
            if (targetIdentity.idList == this.questionEntity.idList && this._isOpen) {
                objectParams = {};
                objectParams.startHeight = this._titleHeight + this._textHeight;
                objectParams.heightTarget = this._titleHeight;
                objectParams.startRotate = 180;
                objectParams.rotateTarget = 0;

                this._isOpen = !this._isOpen;
            }
        }

        if (objectParams) {
            this.createTweenToggle(objectParams.startHeight, objectParams.heightTarget, objectParams.startRotate, objectParams.rotateTarget);
        }
    }

    private createTweenToggle(startHeight: number, heightTarget: number, startRotate: number, rotateTarget: number): void {
        this._isTweenRuning = true;
        this._tweenService.updateTween.emit();

        let initialObject: any = { height: startHeight, rotateChevron: startRotate };
        let targetObject: any = { height: heightTarget, rotateChevron: rotateTarget };
        let tween: TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 350);
        tween.onUpdate((updateObject: any) => this.updateValueToggle(updateObject));
        tween.onComplete(() => this.completeValueToggle());
        tween.easing(TWEEN.Easing.Circular.Out);
        tween.start();
    }

    private updateValueToggle(updateObject: any): void {
        this.content.nativeElement.style.height = Math.round(updateObject.height) + "px";
        this.chevronQuestion.nativeElement.style.transform = 'rotate(' + Math.round(updateObject.rotateChevron) + 'deg)';
    }

    private completeValueToggle(): void {
        this._tweenService.completedTween.emit();
        this._isTweenRuning = false;
    }

    private resizeHandler(event: UIEvent): void {
        this.computeHeight();
        this.content.nativeElement.style.height = this._isOpen ? (this._titleHeight + this._textHeight) + "px" : this._titleHeight + "px";
    }

}