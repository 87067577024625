import { Component, ElementRef, AfterViewInit } from "@angular/core";
import { AbstractPage } from '../abstract.page.component';
import { RouteService } from 'src/app/service/route.service';
import { TweenService } from 'src/app/service/tween.service';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/service/request.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AppEntity } from 'src/app/entities/app.entity';

@Component({
    selector: '',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss']
})
export class SearchPage extends AbstractPage implements AfterViewInit{

    private _subLaunchSearch:Subscription;
    private _listAppInt:Array<AppEntity>;
    private _listAppPro:Array<AppEntity>;
    private _isSearchRunning:boolean;

    public get listAppInt():Array<AppEntity>{
        return this._listAppInt;
    }

    public get listAppPro():Array<AppEntity>{
        return this._listAppPro;
    }

    constructor(routeService:RouteService,
                protected _tweenService:TweenService,
                protected _elRef:ElementRef,
                protected _activeRoute: ActivatedRoute,
                private _requestService:RequestService){
        super(routeService);
    }

    public ngOnInit():void{
        super.ngOnInit();
    }

    public ngAfterViewInit():void{

    }

    public ngOnDestroy():void{
        super.ngOnDestroy();

        this.killSubSearch();
    }

    protected setContent():void{
        super.setContent();
        console.log(this._paramsRoute);
        this.sendSearch(this._paramsRoute.toSearch);
    }

    private sendSearch(contentSearch:string):void{
        if(!this._isSearchRunning){
            console.log('sendSearch', contentSearch);
            this._isSearchRunning = true;
            this._subLaunchSearch = this._requestService.sendSearch(contentSearch).subscribe((res:Response) => this.getSearchHandler(res), (err:HttpErrorResponse) => this.getErrorHandler(err));
        }
    }

    private getSearchHandler(res:Response):void{
        console.log(res);
        this.killSubSearch();
        this._isSearchRunning = false;
        this.processResultApp(res);
    }

    private getErrorHandler(err:HttpErrorResponse):void{
        console.log('error', err);
        this._isSearchRunning = false;
        this.killSubSearch();
    }

    private killSubSearch():void{
        if(this._subLaunchSearch){
            this._subLaunchSearch.unsubscribe();
            this._subLaunchSearch = null;
        }
    }

    private processResultApp(res:Response):void{
        let result:any = res;
        let listResult:Array<any> = result.response;
        let listType:any;
        let type:number;
        let listAppByType:Array<any>;
        let listApp:Array<AppEntity>;
        let appEntitie:AppEntity;
        for(let i:number = 0; i < listResult.length; i++){
            listType = listResult[i];
            type = listType.type;
            listAppByType = listType.listApp;
            listApp = new Array<AppEntity>();

            for(let j:number = 0; j < listAppByType.length; j++){
                appEntitie = new AppEntity(listAppByType[j]);
                listApp.push(appEntitie);
            }

            switch(type){
                case 0 :
                    this._listAppInt = listApp;
                break;
                case 1 :
                    this._listAppPro = listApp;
                break;
                default:
                    console.log("Erreur: ce type d'app n'est pas gérée !!!");
                break;
            }
        }
    }

}