import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/service/request.service';
import { RouteService } from 'src/app/service/route.service';
import { TweenService } from 'src/app/service/tween.service';
import { AbstractPage } from '../abstract.page.component';
import { Application } from '../models/application';

@Component({
    selector: "",
    templateUrl: "./test-app-list.page.html",
    styleUrls: ["test-app-list.page.scss"]
})
export class ListTestAppPage extends AbstractPage {

    private _listAppTest: Array<Application>;
    private _subListAppTest: Subscription;

    public get listAppTest(): Array<Application> {
        return this._listAppTest;
    }

    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        private _requestService: RequestService) {
        super(routeService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._listAppTest = new Array<Application>();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subListAppTest) {
            this._subListAppTest.unsubscribe();
        }
    }

    protected setContent(): void {
        this._subListAppTest = this._requestService.getAppListTest().subscribe((res: Application[]) => this.getListHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
    }

    private getListHandler(res: Application[]): void {
        this._listAppTest = res;
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log('error', err);
    }

}