import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Injectable()
export class DialogService {

  registeredDialog: DialogComponent;

  register(dialog: DialogComponent) {
    this.registeredDialog = dialog;
  }

  constructor(){
}

  show(title:string,body:string) {
    return new Promise<void>((resolve, reject) => {

       
      this.registeredDialog.show(title,body);
      this.registeredDialog.onOk.subscribe(() => {
        this.registeredDialog.hide();
        resolve();
      });
      this.registeredDialog.onCancel.subscribe(() => {
        this.registeredDialog.hide();
        reject();
      });

    });
  }

}