import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RouteService } from '../service/route.service';
import { LoginPage } from '../page/login/login.page';
import { AppListPage } from '../page/app-list/app-list.page';
import { ListTestAppPage } from '../page/test-app-list/test-app-list.page';
import { FaqPage } from '../page/faq/faq.page';
import { AppSheetPage } from '../page/app-sheet/app-sheet.page';
import { AboutPage } from '../page/about/about.page';
import { SearchPage } from '../page/search/search.page';
import { FicheAppTestPage } from '../page/component/ficheAppTest/ficheAppTest.page';


const routes: Routes = [
  { path: '', component: LoginPage },
  { path: RouteService.ROUTE_LOGIN, component: LoginPage },
  { path: RouteService.ROUTE_LIST_APP, component: AppListPage },
  { path: RouteService.ROUTE_LIST_APP_TEST, component: ListTestAppPage },
  { path: RouteService.ROUTE_FAQ, component: FaqPage },
  { path: RouteService.ROUTE_FICHE_APP_TEST + '/:idApp', component: FicheAppTestPage },
  { path: RouteService.ROUTE_FICHE_APP + '/:idApp', component: AppSheetPage },
  { path: RouteService.ROUTE_ABOUT_APP + '/:idApp', component: AboutPage },
  { path: RouteService.ROUTE_SEARCH_RESULT + '/:toSearch', component: SearchPage },
  { path: '**', redirectTo: RouteService.ROUTE_LOGIN, pathMatch: 'full' }
  //{ path: '**', component: LoginPage }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
