import { OnInit, OnDestroy, ElementRef, AfterViewChecked, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import * as TWEEN from '@tweenjs/tween.js';

import { RouteService } from '../service/route.service';
import { TweenService } from '../service/tween.service';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class AbstractPage implements OnInit, AfterViewChecked, OnDestroy{

    protected _tweenService:TweenService;
    protected _routeService:RouteService;
    protected _elRef:ElementRef;
    protected _activeRoute: ActivatedRoute;
    protected _currentURL:string;
    protected _isInit:boolean;
    protected _isToOpen:boolean;
    protected _targetDisplay:any;
    protected _paramsRoute:any;

    private _subRouteStartChange:Subscription;
    private _subRouteIsChanged:Subscription;
    private _subParamsRouter:Subscription;

    constructor(routeService:RouteService){
        this._routeService = routeService;
        this._subRouteStartChange = this._routeService.routeStartedChange.subscribe((url:string) => this.closePage(url));
        this._subRouteIsChanged = this._routeService.routeIsChanged.subscribe((param:any) => this.openPage(param));
    }

    public ngOnInit():void{
        this._targetDisplay = this._elRef.nativeElement.children[0];
        this._isInit = true;
        if(this._isToOpen){
            this.startChangePage(true);
        }
    }

    public ngAfterViewChecked():void{
        if(this._currentURL != undefined && this._currentURL != '' && this._currentURL != '/'&& this._currentURL != '/' + RouteService.ROUTE_LOGIN){
            let stylesHeaderContent:CSSStyleDeclaration = window.getComputedStyle(this._targetDisplay.children[0]);
            let stylesFooterContent:CSSStyleDeclaration = window.getComputedStyle(this._targetDisplay.children[2]);
            this._targetDisplay.children[1].style.minHeight = window.innerHeight - parseInt(stylesHeaderContent.height) - parseInt(stylesFooterContent.height) - (parseInt(stylesFooterContent.marginTop)) + "px";
        }
    }

    public ngOnDestroy():void{
        this._subRouteStartChange.unsubscribe();
        this._subRouteIsChanged.unsubscribe();

        if(this._subParamsRouter){
            this._subParamsRouter.unsubscribe();
        }
    }

    private openPage(param:any):void{
        this._currentURL = param.url;
        
        if(this._isInit){
            this.startChangePage(true);
        }else{
            this._isToOpen = true;
        }
    }

    private closePage(url:string):void{
        if(url[0] != '/'){
            url = '/' + url;
        }

        if(url != this._currentURL){
            this.startChangePage(false);
        }
    }

    private startChangePage(isToOpen:boolean):void{
        this._tweenService.updateTween.emit();
        if(isToOpen){
            this.appear();
        }else{
            this.disappear();
        }
    }

    private appear():void{
        this.setContent();
        this.createTween(1);
    }

    private disappear():void{
        this.createTween(0);
    }

    private createTween(targetAlpha:number):void{
        let initialObject:any = {alpha: this._targetDisplay.style.opacity};
        let targetObject:any = {alpha: targetAlpha};
        let tween:TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 400);
        tween.onUpdate((updateObject:any) => this.updateValue(updateObject));
        tween.onComplete(() => this.completeValue());
        tween.easing(TWEEN.Easing.Exponential.In);
        tween.start();
    }

    private updateValue(updateObject:any):void{
        this._targetDisplay.style.opacity = updateObject.alpha;
    }

    private completeValue():void{
        this._tweenService.completedTween.emit();
        if(this._targetDisplay.style.opacity == 0){
            this._routeService.routeEndedChange.emit();
        }
    }

    /**
     * Etendre cette fonction pour afficher ou charger le contenu
     * de la page cible
     */
    protected setContent():void{
        if(this._activeRoute){
            this._subParamsRouter = this._activeRoute.paramMap.subscribe((params:any) => { this.paramsRouteHandler(params)});
        }
    }

    /**
     * Ecouteur générique de l'initialisation des paramètres contenu dans l'url
     * de la page
     * 
     * @param params Objet contenant la liste des paramètres get de l'url
     */
    protected paramsRouteHandler(params:any):void{
        this._paramsRoute = params.params;
    }

}