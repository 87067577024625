import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ActionService{

    public launchSearch:EventEmitter<any>;
    public toggleBurgerMenu:EventEmitter<any>;
    public carouselStartMove:EventEmitter<any>;
    public carouselMove:EventEmitter<any>;
    public carouselStopMove:EventEmitter<any>;
    public tabClickButton:EventEmitter<any>;
    public clickFAQQuestion:EventEmitter<any>;
    public resizeEvent:EventEmitter<any>;

    constructor(){
        this.launchSearch = new EventEmitter<any>();
        this.toggleBurgerMenu = new EventEmitter<any>();
        this.carouselStartMove = new EventEmitter<any>();
        this.carouselMove = new EventEmitter<any>();
        this.carouselStopMove = new EventEmitter<any>();
        this.tabClickButton = new EventEmitter<any>();
        this.clickFAQQuestion = new EventEmitter<any>();
        this.resizeEvent = new EventEmitter<any>();
    }

}