import { Component, ElementRef, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { RequestService } from 'src/app/service/request.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CarouselImgEntity } from 'src/app/entities/carousel-img.entity';
import { CarouselController } from './controller/carousel.controller';
import { ActionService } from 'src/app/service/action.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'or-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {

    @Input() pageCaseService: number;
    @Input() numMinIMG: number;
    @Input() idPageFiche: number;

    /** To tell the parent if the component has items */
    public hasItems = false;

    private _carouselController: CarouselController;
    private _subImg: Subscription;
    private _listImgCarousel: Array<CarouselImgEntity>;

    public get listImgCarousel(): Array<CarouselImgEntity> {
        return this._listImgCarousel;
    }

    constructor(private _elRef: ElementRef,
        private _requestService: RequestService,
        private _actionService: ActionService) {

    }

    public ngOnInit(): void {
        CarouselImgEntity.NUM_MIN_IMG = this.numMinIMG;

        switch (this.pageCaseService) {
            case 0:
                this._subImg = this._requestService.getListImgCarouselPageList().subscribe((res: Response) => this.getListHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
                break;
            case 1:
                this._subImg = this._requestService.getFicheApp(this.idPageFiche).subscribe((res: Response) => this.getFicheHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
                break;
            default:
                console.log("Erreur: ce cas n'est pas géré !!!");
                break;
        }
    }

    public ngOnDestroy(): void {
        if (this._subImg) {
            this._subImg.unsubscribe();
        }

        if (this._carouselController) {
            this._carouselController.dispose();
        }
    }

    private getListHandler(res: Response): void {
        let result: any = res;
        let listUrl: Array<any> = result;
        let listImg = new Array<CarouselImgEntity>();
        let itemImg: CarouselImgEntity;
        let listLength: number = listUrl.length;
        for (let i = 0; i < listLength; i++) {
            let imgUrl = environment.rootApi + '/api/v2/screenshotPromote/438/screenshot_' + listUrl[i].imageId + '.png';
            itemImg = new CarouselImgEntity(i, listUrl[i].applicationId, listUrl[i].name, imgUrl, listLength, 20);
            listImg.push(itemImg);
        }
        this._listImgCarousel = listImg;
        this._carouselController = new CarouselController(this._elRef.nativeElement, this._actionService);
        this.hasItems = listLength != 0;
    }

    private getFicheHandler(res: Response): void {
        let result: any = res;
        let listIds: Array<any> = result.idScreenShotList;
        let listImg = new Array<CarouselImgEntity>();
        let itemImg: CarouselImgEntity;
        let listLength: number = listIds.length;
        for (let i = 0; i < listLength; i++) {
            let imgUrl = environment.rootApi + '/api/v2/screenShot/140/screenshot_' + listIds[i] + '.png';
            itemImg = new CarouselImgEntity(i, undefined, undefined, imgUrl, listLength, 20);
            listImg.push(itemImg);
        }
        this._listImgCarousel = listImg;
        this._carouselController = new CarouselController(this._elRef.nativeElement, this._actionService);
        this.hasItems = listLength != 0;
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log(err);
    }

}