import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEN from '@angular/common/locales/en';
import { I18nService } from './i18n.service';

@Injectable()
export class LocaleService {

    private _locale: string;

    constructor() { 
        this._locale = I18nService.LOCALE_FR;
    }

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        //return this._locale || I18nService.LOCALE_FR;
        return this._locale;
    }

    public registerCulture(culture:string) {
        if (!culture) {
            return;
        }

        switch (culture) {
            case I18nService.LOCALE_EN: 
                this._locale = I18nService.LOCALE_EN;
                registerLocaleData(localeEN);
                break;
            case I18nService.LOCALE_FR: 
                this._locale = I18nService.LOCALE_FR;
                registerLocaleData(localeFr);
                break;
            default:
                this._locale = I18nService.LOCALE_FR;
                registerLocaleData(localeFr);
                break;
        }
    }
}