import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as TWEEN from '@tweenjs/tween.js';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/core/constants/app-settings';
import { UserSessionService } from 'src/app/core/services/user-session.service';
import { ConnectService } from 'src/app/service/connect.service';
import { DialogService } from 'src/app/service/dialog.service';
import { I18nService } from 'src/app/service/i18n.service';
import { LocaleService } from 'src/app/service/locale.service';
import { RouteService } from 'src/app/service/route.service';
import { TweenService } from 'src/app/service/tween.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
    selector: 'nav-root',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit, OnDestroy {

    //@ViewChild('btDiscover') btDiscover: ElementRef;
    @ViewChild('btReturn') btReturn: ElementRef;
    @ViewChild('btContact') btContact: ElementRef;
    @ViewChild('btToggleLocale') btToggleLocale: ElementRef;
    @ViewChild('contentLocale') contentLocale: ElementRef;
    @ViewChild('listLocale') listLocale: ElementRef;
    @ViewChild('btEn_en') boutonEn: ElementRef;
    @ViewChild('cocheEn') cocheEn: ElementRef;
    @ViewChild('btFr_fr') boutonFr: ElementRef;
    @ViewChild('cocheFr') cocheFr: ElementRef;
    @ViewChild('chevronLocale') chevronLocale: ElementRef;
    @ViewChild('appDialog') appDialog: DialogComponent;
    @ViewChild('btTest') btTest: ElementRef;

    private _urlImgAvatar: string;
    private _name: string;
    private _lastname: string;
    private _urlImgDiscover: string;
    private _urlImgTest: string;
    private _urlImgFaq: string;
    private _urlImgFlag: string;
    private _urlIconBack: string;
    private _nameLocale: string;
    private _nameLocaleFr: string;
    private _nameLocaleEn: string;
    private _isLocaleOpen: boolean;
    private _subConnectService: Subscription;
    private _subLocalChange: Subscription;
    private _subRouteIsChanged: Subscription;
    //private _callGoToDiscover: Function;
    private _callReturnHomePage: Function;
    private _callContact: Function;
    private _callGotoTest: Function;
    private _callToggleLocale: Function;
    private _callSelectLanguageEn: Function;
    private _callSelectLanguageFr: Function;
    public dialogTitle: string;
    public dialogBody: string;

    public get urlImgAvatar(): string {
        return this._urlImgAvatar;
    }

    public get name(): string {
        return this._name;
    }

    public get lastname(): string {
        return this._lastname;
    }

    public get urlImgDiscover(): string {
        return this._urlImgDiscover;
    }

    public get urlImgTest(): string {
        return this._urlImgTest;
    }

    public get urlImgFaq(): string {
        return this._urlImgFaq;
    }

    public get urlIconBack(): string {
        return this._urlIconBack;
    }

    public get urlImgFlag(): string {
        return this._urlImgFlag;
    }

    public get nameLocale(): string {
        return this._nameLocale;
    }

    public get nameLocaleFr(): string {
        return this._nameLocaleFr;
    }

    public get nameLocaleEn(): string {
        return this._nameLocaleEn;
    }

    constructor(private _connectService: ConnectService,
        private _sessionService: UserSessionService,
        private _serviceI18n: I18nService,
        private _localService: LocaleService,
        private _tweenService: TweenService,
        private _routeService: RouteService,
        private dialogService: DialogService,
        private translate: TranslateService) {

    }

    public ngOnInit(): void {
        this._nameLocaleFr = I18nService.NAME_LOCALE_FR;
        this._nameLocaleEn = I18nService.NAME_LOCALE_EN;
        //this._callGoToDiscover = (e: MouseEvent) => { this.onOpenPageDiscover(e) };
        this._callReturnHomePage = (e: MouseEvent) => { this.onReturnHomePage(e) };
        this._callContact = (e: MouseEvent) => { this.onOpenMailContact(e) };
        this._callGotoTest = (e: MouseEvent) => { this.onOpenPageTest(e) };
        this._callToggleLocale = (e: MouseEvent) => { this.toggleLocaleHandler(e) };
        this._callSelectLanguageEn = (e: MouseEvent) => { this.onSelectLanguageEn(e) };
        this._callSelectLanguageFr = (e: MouseEvent) => { this.onSelectLanguageFr(e) };
        this._subConnectService = this._connectService.stateConnectChange.subscribe((isConnected: boolean) => { this.onConnectionHandler(isConnected) });
        this._subLocalChange = this._serviceI18n.localeChange.subscribe((locale: string) => this.onLocalChange(locale));
        this._subRouteIsChanged = this._routeService.routeIsChanged.subscribe((param: any) => this.updateStateHandler(param));

        this.onConnectionHandler(sessionStorage[ConnectService.KEY_CONNECTED] == '1');
        this.onLocalChange(this._localService.locale);

    }

    public ngAfterViewInit(): void {
        //this.btDiscover.nativeElement.addEventListener('click', this._callGoToDiscover);
        this.btReturn.nativeElement.addEventListener('click', this._callReturnHomePage);
        this.btContact.nativeElement.addEventListener('click', this._callContact);
        this.btTest.nativeElement.addEventListener('click', this._callGotoTest);
        this.btToggleLocale.nativeElement.addEventListener('click', this._callToggleLocale);
        this.boutonEn.nativeElement.addEventListener('click', this._callSelectLanguageEn);
        this.boutonFr.nativeElement.addEventListener('click', this._callSelectLanguageFr);
        this.dialogService.register(this.appDialog);

        this.onLocalChange(this._localService.locale);
    }

    public ngOnDestroy(): void {
        //this.btDiscover.nativeElement.removeEventListener('click', this._callGoToDiscover);
        this.btReturn.nativeElement.removeEventListener('click', this._callReturnHomePage);
        this.btContact.nativeElement.removeEventListener('click', this._callContact);
        this.btTest.nativeElement.removeEventListener('click', this._callGotoTest);
        this.btToggleLocale.nativeElement.removeEventListener('click', this._callToggleLocale);
        this.boutonEn.nativeElement.removeEventListener('click', this._callSelectLanguageEn);
        this.boutonFr.nativeElement.removeEventListener('click', this._callSelectLanguageFr);
        this._subConnectService.unsubscribe();
        this._subLocalChange.unsubscribe();
        this._subRouteIsChanged.unsubscribe();
    }

    //private onOpenPageDiscover(e: MouseEvent): void {
    //    this._routeService.routeStartedChange.emit(RouteService.ROUTE_LIST_APP);
    //}

    private onReturnHomePage(e: MouseEvent): void {
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_LIST_APP);
    }

    private onOpenPageTest(e: MouseEvent): void {
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_LIST_APP_TEST);
    }

    private onOpenMailContact(e: MouseEvent): void {
        window.location.href = "mailto:" + AppSettings.CONTACT_EMAIL;
    }

    private onOpenPageFAQ(e: MouseEvent): void {
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_FAQ);
    }

    private onConnectionHandler(isConnected: boolean): void {
        if (isConnected) {
            //const girlTitleArray: Array<string> = ['Mme', 'Mlle', 'MRS', "Mrs."];
            //let img: string = girlTitleArray.includes(sessionStorage[ConnectService.KEY_TITLE]) ? './assets/img/list_app/avatar-girl.svg' : './assets/img/list_app/avatar-man.svg';
            this._urlImgAvatar = './assets/img/list_app/avatar.png';
            this._urlIconBack = './assets/img/fiche_app/back-icon.svg';
            this._name = sessionStorage[ConnectService.KEY_NAME];
            this._lastname = sessionStorage[ConnectService.KEY_LASTNAME];
        }
    }

    private onLocalChange(locale: string): void {
        this._urlImgFlag = locale == I18nService.LOCALE_FR ? './assets/img/login_app/fr_flag.svg' : './assets/img/login_app/en_flag.svg';
        this._nameLocale = locale == I18nService.LOCALE_FR ? 'Français' : 'English';
        if (this.cocheEn && this.cocheFr) {
            this.cocheEn.nativeElement.style.opacity = locale == I18nService.LOCALE_FR ? 0 : 1;
            this.cocheFr.nativeElement.style.opacity = locale == I18nService.LOCALE_FR ? 1 : 0;
        }
    }

    private toggleLocaleHandler(e: MouseEvent): void {
        let styleToogleLocale: CSSStyleDeclaration = window.getComputedStyle(this.btToggleLocale.nativeElement);
        let styleListLocale: CSSStyleDeclaration = window.getComputedStyle(this.listLocale.nativeElement);

        let startHeight: number = this._isLocaleOpen ? parseInt(styleToogleLocale.height) + parseInt(styleListLocale.height) : parseInt(styleToogleLocale.height);
        let heightTarget: number = this._isLocaleOpen ? parseInt(styleToogleLocale.height) : parseInt(styleToogleLocale.height) + parseInt(styleListLocale.height) + 30;
        let startRotate: number = this._isLocaleOpen ? 360 : 270;
        let rotateTarget: number = this._isLocaleOpen ? 270 : 360;

        this.createTweenLocale(startHeight, heightTarget, startRotate, rotateTarget);
        this._isLocaleOpen = !this._isLocaleOpen;
    }

    private createTweenLocale(startHeight: number, heightTarget: number, startRotate: number, rotateTarget: number): void {
        this._tweenService.updateTween.emit();

        let initialObject: any = { height: startHeight, rotateChevron: startRotate };
        let targetObject: any = { height: heightTarget, rotateChevron: rotateTarget };
        let tween: TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 350);
        tween.onUpdate((updateObject: any) => this.updateValueLocale(updateObject));
        tween.onComplete(() => this.completeValueLocale());
        tween.easing(TWEEN.Easing.Circular.Out);
        tween.start();
    }

    private updateValueLocale(updateObject: any): void {
        this.contentLocale.nativeElement.style.height = Math.round(updateObject.height) + 'px';
        this.chevronLocale.nativeElement.style.transform = 'rotate(' + Math.round(updateObject.rotateChevron) + 'deg)';
    }

    private completeValueLocale(): void {
        this._tweenService.completedTween.emit();
    }

    private onSelectLanguageEn(e: MouseEvent): void {
        this._serviceI18n.localeChange.emit(I18nService.LOCALE_EN);
    }

    private onSelectLanguageFr(e: MouseEvent): void {
        this._serviceI18n.localeChange.emit(I18nService.LOCALE_FR);
    }

    private onLogout(): void {
        this._connectService.stateDisconnect();
        this._sessionService.logout();
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_LOGIN);
    }

    private updateStateHandler(param: any): void {
        let trimUrl: string = param.url[0] == '/' ? param.url.substr(1) : param.url;
        this._urlImgDiscover = trimUrl == RouteService.ROUTE_LIST_APP ? './assets/img/list_app/icon-burger1-bleu.svg' : './assets/img/list_app/icon-burger1.svg';
        this._urlImgTest = trimUrl == RouteService.ROUTE_LIST_APP_TEST ? './assets/img/list_app/icon-burger2-bleu.svg' : './assets/img/list_app/icon-burger2.svg';
        this._urlImgFaq = trimUrl == RouteService.ROUTE_FAQ ? './assets/img/list_app/icon-burger4-bleu.svg' : './assets/img/list_app/icon-burger4.svg';
    }



    goPolitiqueConfid() {
        window.open(AppSettings.PRIVACY_POLICY_LINK, "_blank");
    }

    showDialog() {


        this.translate.get('menu').subscribe((data: any) => {
            this.dialogTitle = data.logout;
            this.dialogBody = data.logoutMsg;
        });

        this.dialogService.show(this.dialogTitle, this.dialogBody)
            .then((res) => {
                console.warn('ok clicked');

                this.onLogout();
            })
            .catch((err) => {
                console.warn('rejected');
            });
    }


}