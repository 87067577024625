export class AppSettings {
    static readonly LOGIN_PATH = '/api/v2/login';
    static readonly USER_PATH = '/api/v2/users/current';
    static readonly DEVICE_REGISTER_PATH = '/api/v2/devices/register';
    static readonly APP_LIST_PATH = '/api/v2/applications';
    static readonly TEST_APP_LIST_PATH = '/api/v2/tests/applications';
    static readonly APP_SHEET_PATH = '/api/v2/applications/';
    static readonly SUBSCRIPTION_PATH = '/api/v2/devices/subscribePush';
    static readonly SEARCH_PATH = 'search.json';
    static readonly CAROUSEL_IMG_LIST_PATH = '/api/v2/applications/promoted';
    static readonly CAROUSEL_IMG_SHEET_PATH = 'listImgCarouselPageFiche.json';
    static readonly ABOUT_CONTENT_PATH = 'contentAbout.json';
    static readonly FAQ_CONTENT_PATH = 'contentFAQ.json';
    static readonly CONTACT_EMAIL = 'contact.casapps@ca-cib.com';
    static readonly PRIVACY_POLICY_LINK = 'https://www.credit-agricole.com/footer/politique-de-protection-des-donnees-a-caractere-personnel';
};
