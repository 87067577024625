import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ConnectService {

    public static readonly KEY_ID: string = 'id';
    public static readonly KEY_NAME: string = 'name';
    public static readonly KEY_LASTNAME: string = 'lastname';
    public static readonly KEY_TITLE: string = 'title';
    public static readonly KEY_CONNECTED: string = 'connected';
    public static readonly IS_CONNECTED: boolean = true;
    public static readonly IS_NOT_CONNECTED: boolean = false;

    public stateConnectChange: EventEmitter<any>;

    constructor() {
        this.stateConnectChange = new EventEmitter<any>();

        if (sessionStorage.length > 0) {
            this.stateConnect(sessionStorage);
        }
    }

    public stateConnect(profileParams: any): void {
        sessionStorage.setItem(ConnectService.KEY_ID, profileParams.uuid);
        sessionStorage.setItem(ConnectService.KEY_NAME, profileParams.firstName);
        sessionStorage.setItem(ConnectService.KEY_LASTNAME, profileParams.lastName);
        sessionStorage.setItem(ConnectService.KEY_TITLE, profileParams.title);
        sessionStorage.setItem(ConnectService.KEY_CONNECTED, '1');

        this.stateConnectChange.emit(ConnectService.IS_CONNECTED);
    }

    public stateDisconnect(): void {
        sessionStorage.clear();

        this.stateConnectChange.emit(ConnectService.IS_NOT_CONNECTED);
    }

}