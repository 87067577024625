import { Component, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AbstractPage } from '../abstract.page.component';
import { TweenService } from 'src/app/service/tween.service';
import { RouteService } from 'src/app/service/route.service';
import { RequestService } from 'src/app/service/request.service';

@Component({
    selector: "",
    templateUrl: "./about.page.html",
    styleUrls: ["./about.page.scss"]
})
export class AboutPage extends AbstractPage{

    @ViewChild('contentTextAbout') contentTextAbout:ElementRef;

    private _subContentAbout:Subscription;

    constructor(routeService:RouteService,
                protected _tweenService:TweenService,
                protected _elRef:ElementRef,
                protected _activeRoute: ActivatedRoute,
                private _requestService:RequestService,){
        super(routeService);
    }

    public ngOnInit():void{
        super.ngOnInit();
    }

    public ngOnDestroy():void{
        super.ngOnDestroy();

        if(this._subContentAbout){
            this._subContentAbout.unsubscribe();
        }
    }

    protected setContent():void{
        super.setContent();
        this._subContentAbout = this._requestService.getAboutApp(this._paramsRoute.idApp).subscribe((res:Response) => this.getContentHandler(res), (err:HttpErrorResponse) => this.getErrorHandler(err));
    }

    private getContentHandler(res:Response):void{
        console.log(res);
        let result:any = res;
        this.contentTextAbout.nativeElement.innerHTML = result.about;
    }

    private getErrorHandler(err:HttpErrorResponse):void{
        console.log(err);
    }

}