<div or-img-carousel>
    <div #btApp>
        <img #contentIMG src="{{ urlImg }}" onmousedown="return false" onmousemove="return false" draggable="false"
            unselectable="on" alt="{{ appName }}" />
        <div>
            <h4 #title>
                {{ 'carousel.title' | translate }}
            </h4>
        </div>
        <div>
            <p>
                {{ appName }}
            </p>
        </div>
    </div>
</div>