import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';


import { RequestService } from 'src/app/service/request.service';
import { TagEntity } from 'src/app/entities/tag.entity';
import { AppEntity } from 'src/app/entities/app.entity';
import { environment } from 'src/environments/environment';
import { RouteService } from 'src/app/service/route.service';
import { TweenService } from 'src/app/service/tween.service';
import { AbstractPage } from '../../abstract.page.component';
import { Application } from '../../models/application';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/service/dialog.service';
import { UserSessionService } from 'src/app/core/services/user-session.service';

@Component({
    selector: 'or-fiche-app-test',
    templateUrl: './ficheAppTest.page.html',
    styleUrls: ['./ficheAppTest.page.scss']
})
export class FicheAppTestPage extends AbstractPage implements AfterViewInit {

    @ViewChild("btAbout") btAbout: ElementRef;

    private _subFicheApp: Subscription;
    private _callGotoAbout: Function;
    private _listTags: Array<TagEntity>;
    private _listRecommanded: Array<AppEntity>;
    private _iconeAppUrl: string;
    private _appId: string;
    private _appName: string;
    private _appVersion: string;
    private _appNewFeaturesDescription: string;
    private _appDescription: string;
    private _downloadURL: string;
    private _listAppTest: Array<Application>;
    private _appLink: string;
    public dialogTitle: string;
    public dialogBody: string;

    public get listAppTest(): Array<Application> {
        return this._listAppTest;
    }

    public get listTags(): Array<TagEntity> {
        return this._listTags;
    }

    public get listRecommanded(): Array<AppEntity> {
        return this._listRecommanded;
    }

    public get iconeAppUrl(): string {
        return this._iconeAppUrl;
    }

    public get appName(): string {
        return this._appName;
    }

    public get appVersion(): string {
        return this._appVersion;
    }

    public get appNewFeaturesDescription(): string {
        return this._appNewFeaturesDescription;
    }

    public get appDescription(): string {
        return this._appDescription;
    }

    public get downloadURL(): string {
        return this._downloadURL;
    }

    public get idApp(): string {
        return this._paramsRoute.idApp;
    }

    public get appLink(): string {
        return this._appLink;
    }

    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        protected _activeRoute: ActivatedRoute,
        private _requestService: RequestService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private _userSessionService: UserSessionService
    ) {
        super(routeService);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this._listAppTest = new Array<Application>();
        this._callGotoAbout = (e: MouseEvent) => { this.gotoAboutHandler(e) };
    }

    public ngAfterViewInit(): void {
        if (this.btAbout)
            this.btAbout.nativeElement.addEventListener('click', this._callGotoAbout);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subFicheApp) {
            this._subFicheApp.unsubscribe();
        }

        if (this.btAbout) {
            this.btAbout.nativeElement.removeEventListener('click', this._callGotoAbout);
        }
    }

    protected setContent(): void {
        super.setContent();
        this._subFicheApp = this._requestService.getFicheApp(this._paramsRoute.idApp).subscribe((res: Response) => this.getFicheHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
    }

    private getFicheHandler(res: Response): void {
        let result: any = res;
        this._appId = result.applicationId;
        this._appName = result.name;
        this._appVersion = result.version;
        this._appNewFeaturesDescription = result.newFeaturesDescription;
        this._appDescription = result.longDescription;
        this._downloadURL = result.downloadURL;
        this._iconeAppUrl = environment.rootApi + '/api/v2/logo/69/logo_' + result.logoId + '.png';
        this._appLink = result.urlStore;
        this._listAppTest = result.testList;

        console.log("this._appVersion : " + this._appVersion)
        this.processTagsResult(result.listTags);
        this.processRecommandedResult(result.listRecommanded);
    }

    private processTagsResult(listTags: Array<any>): void {
        let listTagsEntity = new Array<TagEntity>();
        let tagEntity: TagEntity;

        if (listTags) {
            for (let i = 0; i < listTags.length; i++) {
                tagEntity = new TagEntity(listTags[i]);
                listTagsEntity.push(tagEntity);
            }
        }


        this._listTags = listTagsEntity;
    }

    private processRecommandedResult(listRecommanded: Array<any>): void {
        let listRecommendedEntity = new Array<AppEntity>();
        let recommendedEntity: AppEntity;
        if (listRecommanded) {
            for (let i = 0; i < listRecommanded.length; i++) {
                recommendedEntity = new AppEntity(listRecommanded[i]);
                listRecommendedEntity.push(recommendedEntity);
            }
        }

        this._listRecommanded = listRecommendedEntity;
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log('error', err);
    }

    private gotoAboutHandler(e: MouseEvent): void {
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_ABOUT_APP + "/" + this._paramsRoute.idApp);
    }

    showDialog(appEntity: any) {
        console.log("********");
        this.translate.get('appList').subscribe((data: any) => {
            this.dialogTitle = data.installHead;
            this.dialogBody = data.installMsg;
        });
        console.log("appEntity : " + appEntity);
        this.dialogService.show(this.dialogTitle, this.dialogBody + " " + this._appName + " version " + appEntity.version)
            .then((res) => {
                this.setAppLink(appEntity);
                window.open(this._appLink);
            })
            .catch((err) => {
                console.warn('rejected');
            });
    }

    private setAppLink(appEntity: any): void {
        const platform = this._userSessionService.getPlatform().toLowerCase();
        let downloadUrl = environment.rootApi + '/api/v2/tests/applications/' + this._appId + '/download/';
        const token = this._userSessionService.getToken();
        console.log("platform  : " + platform);
        if (platform === "ios") {
            this._appLink = 'itms-services://?action=download-manifest&url=' + encodeURIComponent(downloadUrl + 'ios/' + appEntity.applicationExecutableId + '/manifest.plist?authToken=') + token;
        } else if (platform === "android") {
            this._appLink = downloadUrl + 'android/' + appEntity.applicationExecutableId + '/' + encodeURIComponent(appEntity.name) + '.apk?authToken=' + token;
        }
        console.log("this._appLink : " + this._appLink);
    }

}