import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserSessionService } from 'src/app/core/services/user-session.service';
import { DialogService } from 'src/app/service/dialog.service';
import { RouteService } from 'src/app/service/route.service';
import { environment } from 'src/environments/environment';
import { Application } from '../../models/application';

@Component({
    selector: 'or-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() appEntity: Application;
    @ViewChild('appSheetButton') ficheButton: ElementRef;

    private _id: number;
    private _imgURL: string;
    private _nameApp: string;
    private _preDownloadAppUrl: string;
    private _descriptionApp: string;
    private _appLink: string;
    private _callFicheApp: EventListenerOrEventListenerObject;
    private _applicationTestFlag: boolean;
    public showDownloadBtnForTestApp: boolean;
    public dialogTitle: string;
    public dialogBody: string;

    public get imgURL(): string {
        return this._imgURL;
    }

    public get nameApp(): string {
        return this._nameApp;
    }


    public get descriptionApp(): string {
        return this._descriptionApp;
    }

    constructor(private _routeService: RouteService, private translate: TranslateService, private _httpClient: HttpClient, private _userSessionService: UserSessionService, private dialogService: DialogService) {

    }

    public ngOnInit(): void {
        this._applicationTestFlag = this.appEntity.testApplicationFlag;
        console.log("ngoninit application test flag status " + this._applicationTestFlag);
        if (this._applicationTestFlag && this._applicationTestFlag != undefined) {
            this.showDownloadBtnForTestApp = false;
        } else {
            this.showDownloadBtnForTestApp = true;
        }
        this._id = this.appEntity.applicationId;
        if (this.appEntity.logoId) {
            this._imgURL = environment.rootApi + '/api/v2/logo/69/logo_' + this.appEntity.logoId + '.png';
        }
        this._preDownloadAppUrl = environment.rootApi + '/api/v2/applications/requestdownload';
        this._nameApp = this.appEntity.name;
        this._descriptionApp = this.appEntity.shortDescription;

        this._callFicheApp = (e: MouseEvent) => { this.onOpenFicheApp(e) };
    }

    public ngAfterViewInit(): void {
        this._applicationTestFlag = this.appEntity.testApplicationFlag;
        if (this._applicationTestFlag && this._applicationTestFlag != undefined) {
            this.showDownloadBtnForTestApp = false;
        } else {
            this.showDownloadBtnForTestApp = true;
        }
        this.ficheButton.nativeElement.addEventListener('click', this._callFicheApp);
    }

    public ngOnDestroy(): void {
        this.showDownloadBtnForTestApp = true;
        this.ficheButton.nativeElement.removeEventListener('click', this._callFicheApp);
    }

    private onOpenFicheApp(e: MouseEvent): void {
        console.log("onOpenFicheApp");
        if (this._applicationTestFlag) {
            this._routeService.routeStartedChange.emit(RouteService.ROUTE_FICHE_APP_TEST + "/" + this._id);
        }
        else {
            this._routeService.routeStartedChange.emit(RouteService.ROUTE_FICHE_APP + "/" + this._id);
        }
    }

    public openApp($event) {
        // Prevent default behavior when clicking a link
        $event.preventDefault();

        // Get filename from href
        let filename = this._appLink;

        this._httpClient.get(filename, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                let file = new Blob([data], { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);

                // Open new windows and show PDF
                console.log("open Hey");
                window.open(fileURL);
            });
    }

    /**
     * 
     */
    showDialog() {
        this.translate.get('appList').subscribe((data: any) => {
            this.dialogTitle = data.installHead;
            this.dialogBody = data.installMsg;
        });


        this.dialogService.show(this.dialogTitle, this.dialogBody + " " + this._nameApp)
            .then((res) => {
                const body = {
                    applicationId: this.appEntity.applicationId,
                    os: this._userSessionService.getPlatform().toLowerCase(),
                    authToken: this._userSessionService.getToken()
                };
                const headers = new HttpHeaders().set('Content-Type', 'application/json');
                this._httpClient.post<any>(this._preDownloadAppUrl, body, { headers }).subscribe(
                    response => {
                        console.log(response)
                        if (response.data) {
                            let codeDownload = response.data;
                            this.setAppLink(codeDownload);
                            window.open(this._appLink);
                        }
                    });
            })
            .catch((err) => {
                console.warn('rejected');
            });
    }
    /**
     * 
     * @param codeDownload 
     */
    private setAppLink(codeDownload: any): void {
        const platform = this._userSessionService.getPlatform().toLowerCase();
        let downloadUrl = environment.rootApi + '/api/v2/applications/download/';
        if (platform === "ios") {
            this._appLink = 'itms-services://?action=download-manifest&url=' + encodeURIComponent(downloadUrl + 'ios/manifest.plist?codeDownload=') + codeDownload;
        } else if (platform === "android") {
            this._appLink = downloadUrl + 'android?codeDownload=' + codeDownload;
        }
        console.log("downloadUrl : " + this._appLink);
    }
}