import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class RouteService{

    public static ROUTE_LOGIN:string = 'login';
    public static ROUTE_LIST_APP:string = 'list-application';
    public static ROUTE_LIST_APP_TEST:string = 'list-application-test';
    public static ROUTE_FAQ:string = 'faq';
    public static ROUTE_FICHE_APP_TEST:string = 'fiche-application-test';
    public static ROUTE_FICHE_APP:string = 'fiche-application';
    public static ROUTE_ABOUT_APP:string = 'about';
    public static ROUTE_SEARCH_RESULT:string = 'search';

    public routeStartedChange:EventEmitter<any>;
    public routeEndedChange:EventEmitter<any>;
    public routeIsChanged:EventEmitter<any>;

    constructor(){
        this.routeStartedChange = new EventEmitter<any>();
        this.routeEndedChange = new EventEmitter<any>();
        this.routeIsChanged = new EventEmitter<any>();
    }

}