import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { I18nService } from 'src/app/service/i18n.service';
import { RequestService } from 'src/app/service/request.service';
import { TweenService } from 'src/app/service/tween.service';
import { RouteService } from '../../service/route.service';
import { AbstractPage } from '../abstract.page.component';
import { Application } from '../models/application';

@Component({
    selector: '',
    templateUrl: './app-list.page.html',
    styleUrls: ['./app-list.page.scss']
})
export class AppListPage extends AbstractPage {

    private _subListApp: Subscription;
    private _subListAppTest: Subscription;
    private _subI18n: Subscription;
    private _date: Date;
    private _listAppInt: Array<Application>;
    private _listAppTest: Array<Application>;
    private _listAppPro: Array<Application>;


    public get date(): Date {
        return this._date;
    }

    public get listAppInt(): Array<Application> {
        return this._listAppInt;
    }

    public get listAppTest(): Array<Application> {
        return this._listAppTest;
    }

    public get listAppPro(): Array<Application> {
        return this._listAppPro;
    }



    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        private _requestService: RequestService,
        private _serviceI18n: I18nService) {
        super(routeService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._subI18n = this._serviceI18n.localeChange.subscribe((locale: string) => this.onLocalChange(locale));
        this._date = new Date();
        this._listAppInt = new Array<Application>();
        this._listAppTest = new Array<Application>();
        this._listAppPro = new Array<Application>();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subListApp) {
            this._subListApp.unsubscribe();
        }

        if (this._subListAppTest) {
            this._subListAppTest.unsubscribe();
        }

        if (this._subI18n) {
            this._subI18n.unsubscribe();
        }
    }

    protected setContent(): void {
        this._subListApp = this._requestService.getAppList().subscribe((res: Application[]) => this.getListHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
        this._subListAppTest = this._requestService.getAppListTest().subscribe((res: Application[]) => this.getListAppTestHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));

    }

    private getListHandler(res: Application[]): void {
        this.processResultApp(res);
    }

    private getListAppTestHandler(res: Application[]): void {
        this.processResultAppTest(res);
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log('error', err);
    }

    private processResultApp(res: Application[]): void {
        this._listAppInt = res;
        this._listAppPro = res;

        /*let result:any = res;
        let listResult:Array<Application> = result.response;
        let listType:any;
        let type:number;
        let listAppByType:Array<any>;
        let listApp:Array<AppEntity> = res
        let appEntitie:AppEntity;
        for(let i:number = 0; i < listResult.length; i++){
            listType = listResult[i];
            type = listType.type;
            listAppByType = listType.listApp;
            listApp = new Array<AppEntity>();

            for(let j:number = 0; j < listAppByType.length; j++){
                appEntitie = new AppEntity(listAppByType[j]);
                listApp.push(appEntitie);
            }

            switch(type){
                case 0 :
                    this._listAppInt = listApp;
                break;
                case 1 :
                    this._listAppPro = listApp;
                break;
                default:
                    console.log("Erreur: ce type d'app n'est pas gérée !!!");
                break;
            }
        }*/
    }

    private processResultAppTest(res: Application[]): void {
        this._listAppTest = res;


    }

    private onLocalChange(locale: string): void {
        this._date = new Date();
    }

}