import { AfterViewChecked, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as TWEEN from '@tweenjs/tween.js';
import { ActionService } from '../service/action.service';
import { ConnectService } from '../service/connect.service';
import { I18nService } from '../service/i18n.service';
import { LocaleService } from '../service/locale.service';
import { RouteService } from '../service/route.service';
import { TweenService } from '../service/tween.service';


@Component({
  selector: 'app-root',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, AfterViewChecked {
  private _newURL: string;
  private _currentURL: string;
  private _isBurgerOpen: boolean;
  private _menuBurgerRootElement: any;
  private _contentRootElement: any;
  private _timeTween: any;
  /**
   * 
   * @param _routeService 
   * @param _serviceI18n 
   * @param _translateService 
   * @param _tweenService 
   * @param _localeService 
   * @param _actionService 
   * @param _router 
   * @param _elRef 
   * @param _render 
   */
  constructor(private _routeService: RouteService,
    private _serviceI18n: I18nService,
    private _translateService: TranslateService,
    private _tweenService: TweenService,
    private _localeService: LocaleService,
    private _actionService: ActionService,
    private _router: Router,
    private _elRef: ElementRef,
    private _render: Renderer2) {
    this._translateService.setDefaultLang(this._localeService.locale);
  }
  /**
   * 
   */
  public ngOnInit(): void {
    this._menuBurgerRootElement = this._elRef.nativeElement.children[0].children[0].children[0].children[0].children[1];
    this._contentRootElement = this._elRef.nativeElement.children[0].children[0].children[0].children[0].children[0];

    this._router.events.subscribe((event: Event) => this.onRouterEvent(event));
    this._routeService.routeStartedChange.subscribe((url: string) => this.startChangeURL(url));
    this._routeService.routeEndedChange.subscribe(() => this.endChangeUrl());
    this._serviceI18n.localeChange.subscribe((locale: string) => this.onLocalChange(locale));
    this._tweenService.updateTween.subscribe(() => { this.onTweenUpdate() });
    this._tweenService.completedTween.subscribe(() => { this.onTweenCompleted() });
    this._actionService.toggleBurgerMenu.subscribe((isBurgerOpen: boolean) => { this.toggleBurgerMenu(isBurgerOpen) });
    window.addEventListener('resize', (event: UIEvent) => { this.onResize(event) });
  }
  /**
   * 
   */
  public ngAfterViewChecked(): void {
    let stylesGlobalContent: CSSStyleDeclaration = document.children ? window.getComputedStyle(document.children[0]) : window.getComputedStyle(document.body);
    let heightContentScreen: number = parseInt(stylesGlobalContent.height);
    let contentHeight: number = this._contentRootElement.clientHeight;
    let targetHeight: number = heightContentScreen > contentHeight ? heightContentScreen : contentHeight;
    this._render.setStyle(this._elRef.nativeElement.children[0], "height", targetHeight + "px");
    this._render.setStyle(this._elRef.nativeElement.children[0], "minHeight", targetHeight + "px");
  }
  /**
   * 
   * @param event 
   */
  private onRouterEvent(event: Event): void {
    if (event instanceof NavigationEnd) {
      this._currentURL = event.url == event.urlAfterRedirects ? event.url : event.urlAfterRedirects;
      this.dispatchNewRoute();
      this.changeBackground();
    }
  }

  private startChangeURL(url: string): void {
    this._newURL = url;

    if (this._isBurgerOpen) {
      this.menuStateChange(this._isBurgerOpen);
    }
  }

  private endChangeUrl(): void {
    this._router.navigateByUrl(this._newURL);
  }

  private dispatchNewRoute(): void {
    if (sessionStorage[ConnectService.KEY_CONNECTED] == '1') {
      this._routeService.routeIsChanged.emit({ url: this._currentURL });
    } else {
      this._router.navigateByUrl(RouteService.ROUTE_LOGIN);
    }
  }

  private changeBackground(): void {
    switch (this._currentURL) {
      case '':
      case '/':
      case '/' + RouteService.ROUTE_LOGIN:
        this.setBackground('url(./assets/img/login_app/bg-login.png)');
        break;
      default:
        this.setBackground('none');
        break;
    }
  }

  private setBackground(bgURL: string): void {
    this._elRef.nativeElement.children[0].children[0].children[0].style.backgroundImage = bgURL;
  }

  private onLocalChange(locale: string): void {
    this._translateService.use(locale);
    this._localeService.registerCulture(locale);
  }

  private onTweenUpdate(): void {
    TweenService.COUNT_USER++;
    if (TweenService.COUNT_USER == 1) {
      this._timeTween = setInterval(() => this.renderTween(), 20);
    }
  }

  private onTweenCompleted(): void {
    TweenService.COUNT_USER = TweenService.COUNT_USER > 0 ? TweenService.COUNT_USER - 1 : 0;
    if (TweenService.COUNT_USER == 0) {
      clearInterval(this._timeTween);
    }
  }

  private renderTween(): void {
    TWEEN.update();
  }

  private menuStateChange(isBurgerOpen: boolean): void {
    this._actionService.toggleBurgerMenu.emit(this._isBurgerOpen);
  }

  private toggleBurgerMenu(isBurgerOpen: boolean): void {
    this._isBurgerOpen = !isBurgerOpen;
    this.navUpdatePosition(isBurgerOpen);
    let stylesGlobalContentMenuBurger: CSSStyleDeclaration = window.getComputedStyle(this._menuBurgerRootElement);
    let widthMenuBurger: number = parseInt(stylesGlobalContentMenuBurger.width);
    this._tweenService.updateTween.emit();
    if (isBurgerOpen) {
      this.createTweenBurger(-widthMenuBurger, 0);
    } else {
      this.createTweenBurger(0, widthMenuBurger);
    }
  }

  private createTweenBurger(targetPosXMenu: number, targetPosXContent: number): void {
    let stylesGlobalContentMenuBurger: CSSStyleDeclaration = window.getComputedStyle(this._menuBurgerRootElement);
    let stylesGlobalContentContent: CSSStyleDeclaration = window.getComputedStyle(this._contentRootElement);
    let initialObject: any = { posXMenu: parseInt(stylesGlobalContentMenuBurger.left), posXContent: parseInt(stylesGlobalContentContent.left) };
    let targetObject: any = { posXMenu: targetPosXMenu, posXContent: targetPosXContent };
    let tween: TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
    tween.to(targetObject, 350);
    tween.onUpdate((updateObject: any) => this.updateValueBurger(updateObject));
    tween.onComplete(() => this.completeValueBurger());
    tween.easing(TWEEN.Easing.Cubic.In);
    tween.start();
  }

  private updateValueBurger(updateObject: any): void {
    this._menuBurgerRootElement.style.left = Math.round(updateObject.posXMenu) + "px";
    this._contentRootElement.style.left = Math.round(updateObject.posXContent) + "px";
  }

  private completeValueBurger(): void {
    this._tweenService.completedTween.emit();
  }

  private navUpdatePosition(isOpen: boolean): void {
    let stylesGlobalContentMenuBurger: CSSStyleDeclaration = window.getComputedStyle(this._menuBurgerRootElement);
    if (isOpen) {
      this._render.setStyle(this._menuBurgerRootElement, "left", "0px");
    } else {
      this._render.setStyle(this._menuBurgerRootElement, "left", (-1 * parseInt(stylesGlobalContentMenuBurger.width)).toString() + "px");
    }
  }

  private onResize(event: UIEvent): void {
    this.ngAfterViewChecked();
    let stylesGlobalContentMenuBurger: CSSStyleDeclaration = window.getComputedStyle(this._menuBurgerRootElement);
    let widthMenuBurger: number = parseInt(stylesGlobalContentMenuBurger.width);
    let oPosition: any = this._isBurgerOpen ? { posXMenu: 0, posXContent: widthMenuBurger } : { posXMenu: -widthMenuBurger, posXContent: 0 };
    this.updateValueBurger(oPosition);
    this._actionService.resizeEvent.emit(event);
  }

}
