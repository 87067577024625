<div id="or-structure" class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div>
                <div id="content">
                    <router-outlet></router-outlet>
                </div>
                <div id="menu-burger">
                    <nav>
                        <nav-root></nav-root>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>