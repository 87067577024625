<div or-search>
    <header>
        <or-header [_isWithBurger]=false [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <article>
            <div class="row">
                <div>
                    <h3>{{ 'appList.appInt' | translate }}</h3>
                </div>
                <div>
                    <div *ngFor="let appEntity of listAppInt">
                        <or-app [appEntity]="appEntity">
                        </or-app>
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    <h3>{{ 'appList.appPro' | translate }}</h3>
                </div>
                <div>
                    <div *ngFor="let appEntity of listAppPro">
                        <or-app [appEntity]="appEntity">
                        </or-app>
                    </div>
                </div>
            </div>
        </article>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>