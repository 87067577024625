<div or-app-component>
    <div #appSheetButton>
        <img src="{{ imgURL }}" alt="logo" />
        <div>
            <h5>{{ nameApp }}</h5>
        </div>
        <div>
            <p>{{(descriptionApp?.length>35)? (descriptionApp | slice:0:35)+'...':(descriptionApp) }}</p>
        </div>
    </div>
    <div>
        <div>
            <a target="_blank" (click)="showDialog()" *ngIf="showDownloadBtnForTestApp">
                <div>
                    <p>
                        {{ 'appComponent.download' | translate }}
                    </p>
                </div>
            </a>
        </div>
    </div>
</div>