import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SwPush } from '@angular/service-worker';
import * as TWEEN from '@tweenjs/tween.js';
import { AngularDeviceInformationService } from 'angular-device-information';
import { Subscription } from 'rxjs';
import { UserSessionService } from 'src/app/core/services/user-session.service';
import { Device } from 'src/app/entities/device.entity';
import { ConnectService } from 'src/app/service/connect.service';
import { I18nService } from 'src/app/service/i18n.service';
import { RequestService } from 'src/app/service/request.service';
import { StorageService } from 'src/app/service/storage.service';
import { TweenService } from 'src/app/service/tween.service';
import * as uuid from "uuid";
import { RouteService } from '../../service/route.service';
import { AbstractPage } from '../abstract.page.component';
import { OrPushSubscription } from '../models/orPushSubscription';

@Component({
    selector: '',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage extends AbstractPage implements AfterViewInit {

    @ViewChild('comboBox') comboBox: ElementRef;
    @ViewChild('listComboBox') listComboBox: ElementRef;
    @ViewChild('btEn_en') boutonEn: ElementRef;
    @ViewChild('cocheEn') cocheEn: ElementRef;
    @ViewChild('btFr_fr') boutonFr: ElementRef;
    @ViewChild('cocheFr') cocheFr: ElementRef;
    @ViewChild('chevronComboBox') chevronComboBox: ElementRef;
    @ViewChild('loading') loading: ElementRef;

    public loginForm: UntypedFormGroup;

    private _callOpenComboBox: Function;
    private _callSelectLanguageEn: Function;
    private _callSelectLanguageFr: Function;
    private _subToLogTo: Subscription;
    private _isComboOpen: boolean;
    private _posBottomCombo: number;
    private _posBottomList: number;
    private _heightBt: number;
    private _heightClose: number = 52;
    private _heightOpen: number = 104;
    private _nameLocaleEn: string;
    private _nameLocaleFr: string;

    private _uuid: string;

    public loginFlag: boolean = false;
    public errorLoginMsg: string;
    private languageChoosen: string;

    readonly VAPID_PUBLIC_KEY = "BCqVcOLi-6I--0V0yrpnwpTi_K8XNI2ZdiOp4ru27aUHicIoLzvtFW80T2XfYZUv_ahVFG9jgSvcHWRlmlhtd8w";

    public get nameLocaleEn(): string {
        return this._nameLocaleEn;
    }

    public get nameLocaleFr(): string {
        return this._nameLocaleFr;
    }

    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        private _serviceI18n: I18nService,
        private _formBuilder: UntypedFormBuilder,
        private _requestService: RequestService,
        private _connectService: ConnectService,
        private _userSessionService: UserSessionService,
        private _storageService: StorageService,
        private _deviceInformationService: AngularDeviceInformationService,
        private swPush: SwPush) {
        super(routeService);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.setUUID();
        this.setLanguageChoosen();
        this._nameLocaleEn = I18nService.NAME_LOCALE_EN;
        this._nameLocaleFr = I18nService.NAME_LOCALE_FR;

        this._callOpenComboBox = (e: MouseEvent) => { this.onOpenCombo(e) };
        this._callSelectLanguageEn = (e: MouseEvent) => { this.onSelectLanguageEn(e) };
        this._callSelectLanguageFr = (e: MouseEvent) => { this.onSelectLanguageFr(e) };

        this.loginForm = this._formBuilder.group({
            userDomain: ['CASA_WEB'],
            uuid: [this._uuid],
            login: ['', Validators.required],
            password: ['', Validators.required],
            loginLanguage: [this.languageChoosen]
        });
    }

    public ngAfterViewInit(): void {
        this.comboBox.nativeElement.addEventListener('click', this._callOpenComboBox);

        let styleContentCombo: CSSStyleDeclaration = window.getComputedStyle(this.comboBox.nativeElement);
        this._posBottomCombo = parseInt(styleContentCombo.bottom);

        let styleContentList: CSSStyleDeclaration = window.getComputedStyle(this.listComboBox.nativeElement);
        this._posBottomList = parseInt(styleContentList.bottom);

        let styleContentBtFr: CSSStyleDeclaration = window.getComputedStyle(this.boutonFr.nativeElement);
        this._heightBt = parseInt(styleContentBtFr.height) + parseInt(styleContentBtFr.marginTop) + parseInt(styleContentBtFr.marginBottom);

        this.cocheEn.nativeElement.style.opacity = 0;
        this.loginFlag = false;

        this.loading.nativeElement.style.display = "none";
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subToLogTo) {
            this._subToLogTo.unsubscribe();
        }

        if (this.comboBox) {
            this.comboBox.nativeElement.removeEventListener('click', this._callOpenComboBox);
        }

        if (this.boutonEn) {
            this.boutonEn.nativeElement.removeEventListener('click', this._callSelectLanguageEn);
        }

        if (this.boutonFr) {
            this.boutonFr.nativeElement.removeEventListener('click', this._callSelectLanguageFr);
        }

        if (this._subToLogTo) {
            this._subToLogTo.unsubscribe();
        }
    }

    public onSubmitForm(): void {
        if (this.loginForm.status == 'INVALID') {
            this.loginForm.updateValueAndValidity();
            this.formInvalid();
        } else if (this.loginForm.status == 'VALID') {
            let controlName: string;
            let formControl: UntypedFormControl;
            let isFormValid: boolean = true;
            for (controlName in this.loginForm.controls) {
                formControl = this.loginForm.controls[controlName] as UntypedFormControl;
                if ((formControl as any).nativeElement.value == '') {
                    formControl.setValue('');
                    isFormValid = false;
                }
            }

            if (isFormValid) {
                this.formValid();
            } else {
                this.loginForm.updateValueAndValidity();
                this.formInvalid();
            }
        }
    }

    private formInvalid(): void {
        let controlName: string;
        let formControl: UntypedFormControl;
        for (controlName in this.loginForm.controls) {
            formControl = this.loginForm.controls[controlName] as UntypedFormControl;
            if (formControl.valid && (formControl as any).nativeElement.value != '') {
                this.changeInputStyle(formControl, "none", "#FFFFFF", "0px");
            } else {
                this.changeInputStyle(formControl, "solid", "#FF0000", "1px");
            }
        }
    }

    private formValid(): void {
        this.loading.nativeElement.style.display = "block";
        this.loginForm.patchValue({ loginLanguage: this.languageChoosen })
        this._subToLogTo = this._requestService.login(this.loginForm.value).subscribe((res: HttpResponse<any>) => {
            let token = res.headers.get('X-AUTH-TOKEN');
            if (token) {
                this._userSessionService.saveToken(token);
                this.subscribeToNotifications();
                this.registerDevice();
                this._subToLogTo = this._requestService.user().subscribe((res2: HttpResponse<any>) => {
                    this.logToHandler(res2);
                }, (err: HttpErrorResponse) => this.logToErrorHandler(err)
                );
            }
        }
            , (err: HttpErrorResponse) => {
                this.loading.nativeElement.style.display = "none";
                this.logToErrorHandler(err);
            }
        );

        let controlName: string;
        let formControl: UntypedFormControl;
        for (controlName in this.loginForm.controls) {
            formControl = this.loginForm.controls[controlName] as UntypedFormControl;
            this.changeInputStyle(formControl, "none", "#FFFFFF", "0px");
        }
    }

    private changeInputStyle(target: UntypedFormControl, borderType: string, borderColor: string, borderWidth: string): void {
        (target as any).nativeElement.style.border = borderType;
        (target as any).nativeElement.style.borderColor = borderColor;
        (target as any).nativeElement.style.borderWidth = borderWidth;
        (target as any).nativeElement.style.borderRadius = "13px";
    }

    private logToHandler(res: HttpResponse<any>): void {
        console.log('logToHandler');
        console.log(res);
        this._connectService.stateConnect(res);
        //this._subToLogTo.unsubscribe();
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_LIST_APP);
    }

    private logToErrorHandler(err: HttpErrorResponse): void {
        console.log('logToErrorHandler ', err);
        this.loginFlag = true;
        if (err.error) {
            this.errorLoginMsg = err.error.content;
        } else {
            this.errorLoginMsg = err.message;
        }
    }

    private onOpenCombo(e: MouseEvent): void {
        if (!this._isComboOpen) {
            e.stopImmediatePropagation();
            this._isComboOpen = true;
            this.boutonEn.nativeElement.addEventListener('click', this._callSelectLanguageEn);
            this.boutonFr.nativeElement.addEventListener('click', this._callSelectLanguageFr);
            this.comboBox.nativeElement.removeEventListener('click', this._callOpenComboBox);
            this.createTweenCombo(this._heightOpen, this._posBottomCombo, this._posBottomList, -90);
        }
    }

    private onSelectLanguageEn(e: MouseEvent): void {
        e.stopImmediatePropagation();
        this.configEventSelectedLanguage();
        this.createTweenCombo(this._heightClose, this._posBottomCombo, this._posBottomList - this._heightBt, 0);
        this.cocheEn.nativeElement.style.opacity = 1;
        this.cocheFr.nativeElement.style.opacity = 0;
        this._serviceI18n.localeChange.emit(I18nService.LOCALE_EN);
        this.languageChoosen = 'en';
    }

    private onSelectLanguageFr(e: MouseEvent): void {
        e.stopImmediatePropagation();
        this.configEventSelectedLanguage();
        this.createTweenCombo(this._heightClose, this._posBottomCombo, this._posBottomList, 0);
        this.cocheEn.nativeElement.style.opacity = 0;
        this.cocheFr.nativeElement.style.opacity = 1;
        this._serviceI18n.localeChange.emit(I18nService.LOCALE_FR);
        this.languageChoosen = 'fr';
    }

    private configEventSelectedLanguage(): void {
        this._isComboOpen = false;
        this.comboBox.nativeElement.addEventListener('click', this._callOpenComboBox);
        this.boutonEn.nativeElement.removeEventListener('click', this._callSelectLanguageEn);
        this.boutonFr.nativeElement.removeEventListener('click', this._callSelectLanguageFr);
    }

    private createTweenCombo(paramHeight: number, paramBottom: number, paramBottomList: number, rotate: number): void {
        this._tweenService.updateTween.emit();

        let styleContentCombo: CSSStyleDeclaration = window.getComputedStyle(this.comboBox.nativeElement);
        let styleContentList: CSSStyleDeclaration = window.getComputedStyle(this.listComboBox.nativeElement);
        let initialObject: any = { height: parseInt(styleContentCombo.height), bottom: parseInt(styleContentCombo.bottom), bottomList: parseInt(styleContentList.bottom), rotateChevron: rotate == 0 ? -90 : 0 };
        let targetObject: any = { height: paramHeight, bottom: paramBottom, bottomList: paramBottomList, rotateChevron: rotate };
        let tween: TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 350);
        tween.onUpdate((updateObject: any) => this.updateValueCombobox(updateObject));
        tween.onComplete(() => this.completeValueCombobox());
        tween.easing(TWEEN.Easing.Circular.Out);
        tween.start();
    }

    private updateValueCombobox(updateObject: any): void {
        this.comboBox.nativeElement.style.height = updateObject.height + 'px';
        this.comboBox.nativeElement.style.bottom = updateObject.bottom + 'px';
        this.listComboBox.nativeElement.style.bottom = updateObject.bottomList + 'px';
        this.chevronComboBox.nativeElement.style.transform = 'rotate(' + updateObject.rotateChevron + 'deg)';
    }

    private completeValueCombobox(): void {
        this._tweenService.completedTween.emit();
    }

    private setUUID(): void {
        // Get uuid in storage
        this._uuid = this._storageService.getUUID();
        if (!this._uuid) {
            // uuid not found. Generate new one
            this._uuid = uuid.v4();
            this._storageService.setUUID(this._uuid);

        }
        console.log("setUUID: " + this._uuid);
    }

    private setLanguageChoosen(): void {
        this.languageChoosen = 'fr';

    }

    private registerDevice(): void {
        let device: Device = new Device();
        let deviceInfo = this._deviceInformationService.getDeviceInfo();
        // TODO : VOir ce que l'on fait de ces deux variables
        device.appVersion = "3.1";
        device.userLocale = "fr";
        device.uuid = this._uuid;
        let category: string;
        if (this._deviceInformationService.isMobile()) {
            category = "SMARTPHONE";
        } else if (this._deviceInformationService.isTablet()) {
            category = "TABLET";
        } else if (this._deviceInformationService.isDesktop()) {
            category = "PERSONAL_COMPUTER";
        } else {
            category = this._deviceInformationService.getDeviceType();
        }
        device.deviceCategory = category;
        device.model = "";
        device.osVersion = deviceInfo.osVersion.toString();
        device.platform = deviceInfo.os;
        console.log("Platform: " + device.platform.toLowerCase());
        if(device.platform.toLowerCase() === "mac os x") {
            // Pour débloquer my optim. Peut-on faire mieux ?
            device.platform = "ios";
            device.deviceCategory = "TABLET";
            console.log("Maintenant Platform: " + device.platform);
        }
        this._userSessionService.savePlatform(device.platform);
        this._subToLogTo = this._requestService.registerDevice(device).subscribe(() => { }
            , (err: HttpErrorResponse) => this.logToErrorHandler(err)
        );
    }

    private subscribeToNotifications() {
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
            .then(sub => {
                let orSub: OrPushSubscription = new OrPushSubscription();
                orSub.subscription = sub;
                orSub.uuid = this._uuid;
                console.log("Notification Subscription: ", sub);
                this._requestService.subscribePush(orSub).subscribe(
                    () => console.log('Sent push subscription object to server.'),
                    err => console.log('Could not send subscription object to server, reason: ', err)
                );
            })
            .catch(err => console.error("Could not subscribe to notifications", err));
    }
}