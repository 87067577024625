<div or-header>
    <div class="row">
        <div>
            <div>
                <img #iconeBurger src="{{ urlIconeLeft }}" />
            </div>
            <div>
                <div #titleContent>
                    <h1>CAS'APPS</h1>
                </div>
                <div>
                    <input #searchContent type="text" value="{{ 'header.search' | translate }}" onfocus="value=''" />
                </div>
            </div>
            <div>
                <img #iconeSearch src="{{ urlIconeRight }}" />
            </div>
        </div>
    </div>
</div>