import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { UserSessionService } from 'src/app/core/services/user-session.service';
import { AppEntity } from 'src/app/entities/app.entity';
import { TagEntity } from 'src/app/entities/tag.entity';
import { DialogService } from 'src/app/service/dialog.service';
import { RequestService } from 'src/app/service/request.service';
import { environment } from 'src/environments/environment';
import { RouteService } from '../../service/route.service';
import { TweenService } from '../../service/tween.service';
import { AbstractPage } from '../abstract.page.component';

@Component({
    selector: '',
    templateUrl: './app-sheet.page.html',
    styleUrls: ['./app-sheet.page.scss']
})
export class AppSheetPage extends AbstractPage implements AfterViewInit {

    @ViewChild("btAbout") btAbout: ElementRef;

    private _subFicheApp: Subscription;
    private _callGotoAbout: Function;
    private _listTags: Array<TagEntity>;
    private _listRecommanded: Array<AppEntity>;
    private _iconeAppUrl: string;
    private _appName: string;
    private _appId: string;
    private _appVersion: string;
    private _appNewFeaturesDescription: string;
    private _appDescription: string;
    private _appShortDescription: string;
    private _downloadURL: string;
    public dialogTitle: string;
    public dialogBody: string;

    public get listTags(): Array<TagEntity> {
        return this._listTags;
    }

    public get listRecommanded(): Array<AppEntity> {
        return this._listRecommanded;
    }

    public get iconeAppUrl(): string {
        return this._iconeAppUrl;
    }

    public get appName(): string {
        return this._appName;
    }

    public get appVersion(): string {
        return this._appVersion;
    }

    public get appNewFeaturesDescription(): string {
        return this._appNewFeaturesDescription;
    }

    public get appDescription(): string {
        return this._appDescription;
    }

    public get appShortDescription(): string {
        return this._appShortDescription;
    }

    public get downloadURL(): string {
        return this._downloadURL;
    }

    public get idApp(): string {
        return this._paramsRoute.idApp;
    }

    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        protected _activeRoute: ActivatedRoute,
        private _requestService: RequestService, private translate: TranslateService, private dialogService: DialogService, private _userSessionService: UserSessionService) {
        super(routeService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._callGotoAbout = (e: MouseEvent) => { this.gotoAboutHandler(e) };
    }

    public ngAfterViewInit(): void {
        if (this.btAbout)
            this.btAbout.nativeElement.addEventListener('click', this._callGotoAbout);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subFicheApp) {
            this._subFicheApp.unsubscribe();
        }

        if (this.btAbout) {
            this.btAbout.nativeElement.removeEventListener('click', this._callGotoAbout);
        }
    }

    protected setContent(): void {
        super.setContent();
        this._subFicheApp = this._requestService.getFicheApp(this._paramsRoute.idApp).subscribe((res: Response) => this.getFicheHandler(res), (err: HttpErrorResponse) => this.getErrorHandler(err));
    }

    private getFicheHandler(res: Response): void {
        console.log("HERE AM : ")
        let result: any = res;
        this._appId = result.applicationId;
        this._appName = result.name;
        this._appVersion = result.version;
        this._appNewFeaturesDescription = result.newFeaturesDescription;
        this._appDescription = result.longDescription.replaceAll("&lt;","<").replaceAll("&gt;",">");
        this._appShortDescription = result.shortDescription;
        this._downloadURL = result.urlStore;
        this._iconeAppUrl = environment.rootApi + '/api/v2/logo/80/logo_' + result.logoId + '.png';

        console.log("this._appVersion : " + this._appVersion)
        console.log("this._appId : " + this._appId)
        this.processTagsResult(result.listTags);
        this.processRecommandedResult(result.listRecommanded);
    }

    private processTagsResult(listTags: Array<any>): void {
        let listTagsEntity = new Array<TagEntity>();
        let tagEntity: TagEntity;

        if (listTags) {
            for (let i = 0; i < listTags.length; i++) {
                tagEntity = new TagEntity(listTags[i]);
                listTagsEntity.push(tagEntity);
            }
        }
        this._listTags = listTagsEntity;
    }

    private processRecommandedResult(listRecommanded: Array<any>): void {
        let listRecommendedEntity = new Array<AppEntity>();
        let recommendedEntity: AppEntity;
        if (listRecommanded) {
            for (let i = 0; i < listRecommanded.length; i++) {
                recommendedEntity = new AppEntity(listRecommanded[i]);
                listRecommendedEntity.push(recommendedEntity);
            }
        }
        this._listRecommanded = listRecommendedEntity;
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log('error', err);
    }

    private gotoAboutHandler(e: MouseEvent): void {
        this._routeService.routeStartedChange.emit(RouteService.ROUTE_ABOUT_APP + "/" + this._paramsRoute.idApp);
    }

    showDialog() {
        console.log("********appSheet");
        this.translate.get('appList').subscribe((data: any) => {
            this.dialogTitle = data.installHead;
            this.dialogBody = data.installMsg;
        });

        this.dialogService.show(this.dialogTitle, this.dialogBody + " " + this._appName)
            .then((res) => {
                console.warn('ok clicked');
                this.setDownloadURL();
                window.open(this._downloadURL);
            })
            .catch((err) => {
                console.warn('rejected');
            });
    }

    /**
     * 
     */
    private setDownloadURL(): void {
        const platform = this._userSessionService.getPlatform().toLowerCase();
        let downloadUrl = environment.rootApi + '/api/v2/applications/' + this._appId + '/download/';
        const token = this._userSessionService.getToken();
        if (platform === "ios") {
            this._downloadURL = 'itms-services://?action=download-manifest&url=' + encodeURIComponent(downloadUrl + 'ios/manifest.plist?authToken=') + token;
        } else if (platform === "android") {
            this._downloadURL = downloadUrl + platform + '/' + encodeURIComponent(this.appName) + '.apk?authToken=' + token;
        }
    }
}