<div class="modal fade show" tabindex="-1" role="dialog" [ngStyle]="style">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<!-- <h5 class="modal-title" id="title_tag">{{ 'menu.logout' | translate }}</h5> -->
				<h5 class="modal-title" id="title_tag">{{ titleText }}</h5>
				<button type="button" class="close" (click)="cancelClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
			</div>
			<div class="modal-body" id="message_tag">
				<!-- <p>{{ 'menu.logout_msg' | translate }}</p> -->
				<!-- <p>{{ bodyText }}</p> -->
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="cancelClicked()">{{ 'menu.annulerLogoutBtn' | translate }}</button>
				<button type="button" class="btn btn-primary" (click)="okClicked()">{{ 'menu.okLogoutBtn' | translate }}</button>
			</div>
		</div>
	</div>
</div>