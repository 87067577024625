<div or-listTest>
    <header>
        <or-header [_isWithBurger]=true [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <div class="row">
            <div>
                <h3>{{ 'listAppTest.titleAppTest' | translate }}</h3>
            </div>
            <div>
                <div *ngFor="let appEntity of listAppTest">
                
                    <or-app [appEntity]="appEntity">
                    </or-app>                      
                </div>
            </div>
        </div>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>