export class AppEntity{

    private _id:number;
    private _name:string;
    private _version:string;
    private _newFeaturesDescription:string;
    private _description:string;
    private _urlIcone:string;
    private _downloadURL:string;

    constructor(appParams:any){
        this._id = appParams.id;
        this._name = appParams.name;
        this._version = appParams.version;
        this._newFeaturesDescription = appParams.newFeaturesDescription;
        this._description = appParams.description;
        this._urlIcone = appParams.pictureURL;
        this._downloadURL = appParams.downloadURL;
    }

    public get id():number{
        return this._id;
    }

    public get name():string{
        return this._name;
    }

    public get version():string{
        return this._version;
    }

    public get newFeaturesDescription():string{
        return this._newFeaturesDescription;
    }

    public get description():string{
        return this._description;
    }

    public get urlIcone():string{
        return this._urlIcone;
    }

    public get downloadURL():string{
        return this._downloadURL;
    }

}