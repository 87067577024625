import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { DialogService } from '../service/dialog.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { SafeUrlPipe } from './pipe/safe-url.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),],
  exports: [DialogComponent, SafeUrlPipe],
  declarations: [DialogComponent, SafeUrlPipe],
  providers: [DialogService]
})
export class SharedModule { }