import { ActionService } from 'src/app/service/action.service';
import { AddEventListenerOptions } from 'rxjs/internal/observable/fromEvent';

export class CarouselController{

    private _rootDisplay:any;
    private _actionService:ActionService;
    private _startPointX:number;
    private _startPointY:number;
    private _newMove:number;
    private _isMoveTest:boolean;
    private _isScrolling:boolean;
    private _callFuncStartMove:EventListenerOrEventListenerObject;
    private _callFuncMove:EventListenerOrEventListenerObject;
    private _callFuncEndMove:EventListenerOrEventListenerObject;

    constructor(rootDisplay:any, actionService:ActionService){
        this.build(rootDisplay, actionService);
    }

    public dispose():void{
        this.removeEventTarget();
        this.removeEventWindow();
    }

    private build(rootDisplay:any, actionService:ActionService):void{
        this._rootDisplay = rootDisplay;
        this._actionService = actionService;
        this._startPointX = 0;
        this._newMove = 0;

        this._callFuncStartMove = (e:Event) => { this.onMouseDown(e)};
        this._callFuncMove = (e:Event) => { this.onMouseMove(e)};
        this._callFuncEndMove = (e:Event) => { this.onMouseUp(e)};

        this.addEventTarget();
    }

    private onMouseDown(e:Event):void{
        this.removeEventTarget();

        let startPointX:number = 0;
        let startPointY:number = 0;
        switch(e.type){
            case "mousedown" :
                startPointX = (e as MouseEvent).pageX;
            break;
            case "touchstart" :
                startPointX = (e as TouchEvent).touches[0].pageX;
                startPointY = (e as TouchEvent).touches[0].pageY;
            break;
            default :
                console.log("Cette événement n'est pas géré : " + e.type);
            break;
        }

        this._startPointX = startPointX;
        this._startPointY = startPointY;
        this._newMove = 0;
        this._isMoveTest = false;
        this._isScrolling = true;

        this._actionService.carouselStartMove.emit();
        
        this.addEventWindow();
    }

    private onMouseMove(e:Event):void{
        let posXMouse:number = 0;
        let posYMouse:number = 0;
        switch(e.type){
            case "mousemove" :
                posXMouse = (e as MouseEvent).pageX;
            break;
            case "touchmove" :
                posXMouse = (e as TouchEvent).touches[0].pageX;
                posYMouse = (e as TouchEvent).touches[0].pageY;
            break;
            default :
                console.log("Cette événement n'est pas géré : " + e.type);
            break;
        }

        this._newMove = posXMouse - this._startPointX;
        let newMoveY:number = posYMouse - this._startPointY;
        this._startPointX = posXMouse;
        this._startPointY = posYMouse;

        if(!this._isMoveTest){
            this._isMoveTest = true;
            if(this._newMove == 0 && newMoveY == 0){
                this._isMoveTest = false;
            }else if(Math.abs(this._newMove) + 4 >= Math.abs(newMoveY)){
                this._isScrolling = false;
            }else{
                this._isScrolling = true;
            }
        }

        if(this._isScrolling){
            this._newMove = 0;
        }else{
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            this._actionService.carouselMove.emit(this._newMove);
        }
    }

    private onMouseUp(e:Event):void{
        this.removeEventWindow();
        this.addEventTarget();
        
        if(!this._isScrolling){
            this._actionService.carouselStopMove.emit(this._newMove);
        }
    }

    private addEventTarget():void{
        this._rootDisplay.addEventListener('mousedown', this._callFuncStartMove);
        this._rootDisplay.addEventListener('touchstart', this._callFuncStartMove);
    }

    private addEventWindow():void{
        let options:AddEventListenerOptions = {passive:false, capture:true};
        window.addEventListener('mousemove', this._callFuncMove, options);
        window.addEventListener('touchmove', this._callFuncMove, options);
        window.addEventListener('mouseup', this._callFuncEndMove);
        window.addEventListener('touchend', this._callFuncEndMove);
    }

    private removeEventTarget():void{
        this._rootDisplay.removeEventListener('mousedown', this._callFuncStartMove);
        this._rootDisplay.removeEventListener('touchstart', this._callFuncStartMove);
    }

    private removeEventWindow():void{
        let options:EventListenerOptions = {capture:true};
        window.removeEventListener('mousemove', this._callFuncMove, options);
        window.removeEventListener('touchmove', this._callFuncMove, options);
        window.removeEventListener('mouseup', this._callFuncEndMove);
        window.removeEventListener('touchend', this._callFuncEndMove);
    }

}