import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { DialogService } from '../../service/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {

  onOk: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  private backdrop: HTMLElement;
  style: any;

  public titleText: string;
  public bodyText : string

  constructor( @Inject(DOCUMENT) private document: Document) { }

  okClicked() {
    this.onOk.emit();
  }

  cancelClicked() {
    this.onCancel.emit();
  }

  show(title:string, body:string) {
    this.document.body.classList.add('modal-open');
    this.style = { 'display': 'block' };
    this.showBackdrop(title,body);
  }

  hide() {
    this.document.body.classList.remove('modal-open');
    this.style = { 'display': 'none' };
    this.hideBackdrop();
  }

  showBackdrop(title:string, body:string) {

    this.backdrop =this.document.getElementById("title_tag");
    this.titleText=title
    this.backdrop.textContent=this.titleText;

    this.backdrop = this.document.getElementById('message_tag');
    const p = this.document.createElement("p");
    p.setAttribute("id", "Div1");
    this.bodyText=body;
    p.textContent = this.bodyText;
    this.backdrop.appendChild(p);  

    
    
    
    this.backdrop = this.document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    this.document.body.appendChild(this.backdrop);
  }


  hideBackdrop() {
    const element = this.document.getElementById('Div1');
    element?.remove();
    this.backdrop?.remove();
  }

}