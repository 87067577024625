import { Injectable } from '@angular/core';
const TOKEN = 'casapps-token';
const UUID = 'UUID';
const PLATFORM = 'casapps-platform';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  constructor() { }

  public getToken(): string {
    return localStorage.getItem(TOKEN);
  }

  public saveToken(token: string): void {
    localStorage.setItem(TOKEN, token);
  }

  public getUuid(): string {
    return localStorage.getItem(UUID);
  }

  public saveUuid(uuid: string): void {
    localStorage.setItem(UUID, uuid);
  }

  public getPlatform(): string {
    return localStorage.getItem(PLATFORM);
  }

  public savePlatform(platform: string): void {
    localStorage.setItem(PLATFORM, platform);
  }


  public logout(): void {
    localStorage.removeItem(TOKEN);
  }
}

