import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { LocaleService } from '../service/locale.service';

@Pipe({
    name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform{

    constructor(private _localeService:LocaleService){

    }

    public transform(value:any, format: string):string{
        if(!value){
            console.log('No value !!!');
            return '';
        }

        if(!format){
            format = 'shortDate';
        }

        return formatDate(value, format, this._localeService.locale);
    }

}