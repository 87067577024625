export class QuestionEntity{

    private _id:number;
    private _idList:number;
    private _title:string;
    private _answer:string;
    private _totalList:number;

    public get id():number{
        return this._id;
    }

    public get idList():number{
        return this._idList;
    }

    public get title():string{
        return this._title;
    }

    public get answer():string{
        return this._answer;
    }

    public get totalList():number{
        return this._totalList;
    }

    constructor(params:any){
        this._id = params.id;
        this._idList = params.idList;
        this._title = params.title;
        this._answer = params.answer;
        this._totalList = params.total;
    }

}