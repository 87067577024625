import { Component, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AbstractPage } from '../abstract.page.component';
import { TweenService } from 'src/app/service/tween.service';
import { RouteService } from 'src/app/service/route.service';
import { RequestService } from 'src/app/service/request.service';
import { CategoryTitleEntity } from 'src/app/entities/category-title.entity';
import { QuestionEntity } from 'src/app/entities/question.entity';

@Component({
    selector: '',
    templateUrl: './faq.page.html',
    styleUrls: ['./faq.page.scss']
})
export class FaqPage extends AbstractPage {

    private _subFaqcontent: Subscription;
    private _listFAQCategories: Array<CategoryTitleEntity>;
    private _listFAQContentCategories: Array<Array<QuestionEntity>>;

    public get listFAQCategories(): Array<CategoryTitleEntity> {
        return this._listFAQCategories;
    }

    public get listFAQContentCategories(): Array<Array<QuestionEntity>> {
        return this._listFAQContentCategories;
    }

    constructor(routeService: RouteService,
        protected _tweenService: TweenService,
        protected _elRef: ElementRef,
        private _requestService: RequestService) {
        super(routeService);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._subFaqcontent) {
            this._subFaqcontent.unsubscribe();
        }
    }

    protected setContent(): void {
        super.setContent();

        this._subFaqcontent = this._requestService.getFaqContent().subscribe((res: Response) => { this.getContentHandler(res) }, (err: HttpErrorResponse) => { this.getErrorHandler(err) });
    }

    private getContentHandler(res: Response): void {
        let result: any = res;
        let listCategories: Array<CategoryTitleEntity> = new Array<CategoryTitleEntity>();
        let listContentCategories: Array<Array<QuestionEntity>> = new Array<Array<QuestionEntity>>();
        let categoryTitle: CategoryTitleEntity;
        let question: QuestionEntity;
        let resultListCategories: Array<any> = result.listCategories;
        for (let i: number = 0; i < resultListCategories.length; i++) {
            categoryTitle = new CategoryTitleEntity({ id: i, title: resultListCategories[i].categoryTitle });
            listCategories.push(categoryTitle);
            listContentCategories[i] = new Array<QuestionEntity>();
            for (let j = 0; j < resultListCategories[i].listQuestions.length; j++) {
                resultListCategories[i].listQuestions[j].id = j;
                resultListCategories[i].listQuestions[j].idList = i;
                resultListCategories[i].listQuestions[j].total = resultListCategories[i].listQuestions.length;
                question = new QuestionEntity(resultListCategories[i].listQuestions[j]);
                listContentCategories[i].push(question);
            }
        }

        this._listFAQCategories = listCategories;
        this._listFAQContentCategories = listContentCategories;
    }

    private getErrorHandler(err: HttpErrorResponse): void {
        console.log(err);
    }

}