<div or-faq>
    <header>
        <or-header [_isWithBurger]=false [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <article>
            <div class="row">
                <div id="title-faq">
                    <div>
                        <h2>{{ 'faq.pageTitle' | translate }}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="menu-tab-faq">
                    <div *ngFor="let categoryTitle of listFAQCategories">
                        <tabTitle-root [titleEntity]="categoryTitle"></tabTitle-root>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="content-tab-faq">
                    <div *ngFor="let listQuestions of listFAQContentCategories; index as i">
                        <tabContent-root [listQuestions]="listQuestions" [idList]="i"></tabContent-root>
                    </div>
                </div>
            </div>
        </article>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>