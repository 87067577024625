import { Component, ElementRef, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import TWEEN from '@tweenjs/tween.js';

import { CarouselImgEntity } from 'src/app/entities/carousel-img.entity';
import { TweenService } from 'src/app/service/tween.service';
import { ActionService } from 'src/app/service/action.service';
import { Subscription } from 'rxjs';
import { RouteService } from 'src/app/service/route.service';

@Component({
    selector: 'or-img-carousel',
    templateUrl: './img.item.component.html',
    styleUrls: ['./img.item.component.scss']
})
export class ImgCarouselComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() imgEntity: CarouselImgEntity;
    @ViewChild("btApp") btApp: ElementRef;
    @ViewChild("title") title: ElementRef;
    @ViewChild("contentIMG") contentIMG: ElementRef;

    private _targetDivImg: any;
    private _appName: string;
    private _idApp: number;
    private _urlImg: string;
    private _widthIMG: number;
    private _posXMin: number;
    private _posXMax: number;
    private _widthItems: number;
    private _subStartMove: Subscription;
    private _subMove: Subscription;
    private _subEndMove: Subscription;
    private _callGotoApp: Function;
    private _callLoadCompleted: Function;
    private _isMoving: boolean;

    public get urlImg(): string {
        return this._urlImg;
    }

    public get appName(): string {
        return this._appName;
    }

    public get idApp(): number {
        return this._idApp;
    }

    public get posX(): number {
        let posXTarget: number = 0;
        if (this._targetDivImg) {
            let styleTarget: CSSStyleDeclaration = window.getComputedStyle(this._targetDivImg);
            posXTarget = parseInt(styleTarget.left);
        }

        return posXTarget;
    }

    public set posX(value: number) {
        if (this._targetDivImg) {
            let validPosition = this.checkPosition(value);
            this._targetDivImg.style.left = validPosition + 'px';
        }
    }

    constructor(private _elRef: ElementRef,
        private _tweenService: TweenService,
        private _actionService: ActionService,
        private _routeService: RouteService) {

    }

    public ngOnInit(): void {
        this._appName = this.imgEntity.nameApp;
        this._idApp = this.imgEntity.idApp;
        this._urlImg = this.imgEntity.urlImg;
        this._targetDivImg = this._elRef.nativeElement.children[0].children[0];
        this._widthIMG = 0;

        this._callGotoApp = (e: MouseEvent) => { this.gotoAppHandler(e) };
        this._callLoadCompleted = (event: Event) => { this.updateWithImg(event) };
        this._subStartMove = this._actionService.carouselStartMove.subscribe(() => { this.startMove() });
        this._subMove = this._actionService.carouselMove.subscribe((newMove: number) => { this.move(newMove) });
        this._subEndMove = this._actionService.carouselStopMove.subscribe((newMove: number) => { this.endMove(newMove) });
    }

    public ngAfterViewInit(): void {
        if (this._appName == undefined) {
            this.title.nativeElement.style.display = "none";
        } else {
            this.btApp.nativeElement.addEventListener('mouseup', this._callGotoApp);
        }
        this.contentIMG.nativeElement.addEventListener('load', this._callLoadCompleted);
        this.btApp.nativeElement.style.zIndex = this.imgEntity.totalImg - this.imgEntity.index;
    }

    public ngOnDestroy(): void {
        this.btApp.nativeElement.removeEventListener('mouseup', this._callGotoApp);
        this.contentIMG.nativeElement.removeEventListener('load', this._callLoadCompleted);
        this._subStartMove.unsubscribe();
        this._subMove.unsubscribe();
        this._subEndMove.unsubscribe();
    }

    private updateWithImg(event: Event): void {
        this.contentIMG.nativeElement.removeEventListener('load', this._callLoadCompleted);
        let styleContentImg: CSSStyleDeclaration = window.getComputedStyle(this.contentIMG.nativeElement);
        this._widthIMG = parseInt(styleContentImg.width);
        this.btApp.nativeElement.style.width = styleContentImg.width;
        this.btApp.nativeElement.style.height = styleContentImg.height;
        this.contentIMG.nativeElement.style.zindex = this.imgEntity.totalImg - this.imgEntity.index;

        let widthAndGapImg = this._widthIMG + this.imgEntity.offsetX;
        let calcPosX = (widthAndGapImg * this.imgEntity.index) + 8;
        this._posXMin = this.imgEntity.totalImg > CarouselImgEntity.NUM_MIN_IMG ? 0 - widthAndGapImg : calcPosX - (this.imgEntity.offsetX * 2);
        this._posXMax = this.imgEntity.totalImg > CarouselImgEntity.NUM_MIN_IMG ? 0 + (widthAndGapImg * (this.imgEntity.totalImg - 1)) : calcPosX + (this.imgEntity.offsetX * 2);
        this._widthItems = this._posXMax + Math.abs(this._posXMin);

        this.posX = calcPosX;
    }

    private checkPosition(positionX: number): number {
        let checkPositionX: number;

        if (this.imgEntity.totalImg > CarouselImgEntity.NUM_MIN_IMG) {
            checkPositionX = positionX > this._posXMax ? this._posXMin + Math.abs((positionX - this._posXMax) % this._widthItems) : positionX;
            checkPositionX = positionX < this._posXMin ? this._posXMax - Math.abs((this._posXMin - positionX) % this._widthItems) : checkPositionX;
        } else {
            checkPositionX = positionX > this._posXMax ? this._posXMax : positionX;
            checkPositionX = positionX < this._posXMin ? this._posXMin : checkPositionX;
        }

        return Math.round(checkPositionX);
    }

    private startMove(): void {
        if (this.imgEntity.totalImg > CarouselImgEntity.NUM_MIN_IMG) {
            this._tweenService.completedTween.emit();
            TWEEN.removeAll();
        }
    }

    private move(newMove: number): void {
        this._isMoving = true;
        this.posX = this.posX + newMove;
    }

    private endMove(newMove: number): void {
        this._isMoving = false;
        if (this.imgEntity.totalImg > CarouselImgEntity.NUM_MIN_IMG) {
            this.createTweenStop(newMove);
            this._tweenService.updateTween.emit();
        }
    }

    private createTweenStop(speed: number): void {
        let initialObject = { x: this.posX };
        let targetSpeedX = speed * 10;
        let targetObject = { x: this.posX + targetSpeedX };
        let tween = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 1000);
        tween.onUpdate((objectUpdateByTwee: any) => this.updateItemByTween(objectUpdateByTwee));
        tween.onComplete(() => this.onEndedTween());
        tween.onStop(() => this.onStopTween());
        tween.easing(TWEEN.Easing.Exponential.Out);
        tween.start();
    }

    private updateItemByTween(objectUpdateByTwee: any): void {
        this.posX = Math.round(objectUpdateByTwee.x);
    }

    private onEndedTween(): void {
        this._tweenService.completedTween.emit();
    }

    private onStopTween(): void {
        //console.log('onStopTween');
    }

    private gotoAppHandler(e: MouseEvent): void {
        if (!this._isMoving) {
            this._routeService.routeStartedChange.emit(RouteService.ROUTE_FICHE_APP + '/' + this._idApp);
        }
    }

}