import { Injectable } from '@angular/core';
import { UserSessionService } from '../core/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private userSession: UserSessionService) { }

  public getUUID() {
    return this.userSession.getUuid();
  }

  public setUUID(uuid: string) {
    this.userSession.saveUuid(uuid);
  }
}
