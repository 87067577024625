<div or-fiche>
    <header>
        <or-header [_isWithBurger]=false [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <article>
            <div class="row">
                <div id="app-title">
                    <div>
                        <img src="{{ iconeAppUrl }}" alt="" />
                    </div>
                    <div>
                        <h2>{{ appName }}</h2>
                        <p>{{appShortDescription}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div #btDownload id="download">
                    <a target="_blank" (click)="showDialog()">
                        <div>
                            <p>{{ 'appSheet.install' | translate }}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="row">
                <div id="app-newFeaturesDescription">
                    <div>
                        <h2>{{ 'appSheet.nouveautes' | translate }}</h2>
                        <p id="version">{{ 'appSheet.version' | translate }} {{ appVersion }}</p>
                        <p id="description">{{ appNewFeaturesDescription }}</p>
                    </div>
                </div>
            </div>

            <aside id="carousel" aria-label="carousel">
                <h4>{{ 'appSheet.apercu' | translate }}</h4>
                <div id="row">
                    <div>
                        <or-carousel [pageCaseService]=1 [numMinIMG]=2 [idPageFiche]="idApp">
                        </or-carousel>
                    </div>
                </div>
            </aside>

            <div class="row" id="app-description">
                <h4>{{ 'appSheet.description' | translate }}</h4>
                <div class="p-3 mb-2 bg-light">
                    <p [innerHtml]="appDescription"></p>
                </div>
            </div>
        </article>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>