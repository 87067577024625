<div or-tabQuestion>
    <div #content [ngClass]="{'bottom-border': isWithBorderBottom}">
        <div id="titleContent" #titleContent>
            <div>
                <h5 [ngClass]="{'question-open': isOpen}">{{ title }}</h5>
            </div>
            <div>
                <div>
                    <img #chevronQuestion src="./assets/img/list_app/chevron-burger.svg" />
                </div>
            </div>
        </div>
        <div id="textContent" #textContent>
            <p>
                {{ text }}
            </p>
        </div>
    </div>
</div>