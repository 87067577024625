import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as TWEEN from '@tweenjs/tween.js';

import { TweenService } from 'src/app/service/tween.service';
import { ActionService } from 'src/app/service/action.service';
import { RouteService } from 'src/app/service/route.service';

@Component({
    selector: 'or-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('iconeBurger') iconeBurger: ElementRef;
    @ViewChild('iconeSearch') iconeSearch: ElementRef;
    @ViewChild('titleContent') titleContent: ElementRef;
    @ViewChild('searchContent') searchContent: ElementRef;

    @Input() _isWithBurger: boolean;
    @Input() _isWithSearch: boolean;

    private _callOpenCloseBurger: Function;
    private _callOpenCloseSearch: Function;
    private _callClickSearInput: Function;
    private _callDocumentClick: EventListenerOrEventListenerObject;
    private _callKeyboardUp: EventListenerOrEventListenerObject;
    private _subToogleMenu: Subscription;
    private _isSearch: boolean;
    private _isBurgerOpen: boolean;

    public urlIconeLeft: string;
    public urlIconeRight: string;

    public set isWithBurger(value: boolean) {
        this._isWithBurger = value;

        this.urlIconeLeft = this._isWithBurger ? "./assets/img/list_app/icone-burger-menu.svg" : "./assets/img/fiche_app/back-icon.svg";
    }

    public set isWithSearch(value: boolean) {
        this._isWithSearch = value;

        if (this._isWithSearch) {
            this.urlIconeRight = "./assets/img/list_app/loupe-header.svg";
        }
    }

    constructor(private _tweenService: TweenService,
        private _actionService: ActionService,
        private _routeService: RouteService) {

    }

    public ngOnInit(): void {
        this.isWithBurger = this._isWithBurger;
        this.isWithSearch = this._isWithSearch;
    }

    public ngAfterViewInit(): void {
        this._isBurgerOpen = false;

        if (!this._isWithSearch) {
            this.iconeSearch.nativeElement.style.display = 'none';
        } else {
            this.iconeSearch.nativeElement.style.width = this._isWithBurger ? "100%" : "60%";
            this._callOpenCloseSearch = (e: MouseEvent) => { this.onOpenCloseSearch(e) };
            this._callClickSearInput = (e: MouseEvent) => { this.onSearchInputClick(e) };
            this._callDocumentClick = (e: MouseEvent) => { this.onDocumentClick(e) };
            this._callKeyboardUp = (e: KeyboardEvent) => { this.onSendSearch(e) };
            this.iconeSearch.nativeElement.addEventListener('click', this._callOpenCloseSearch);
        }

        if (this._isWithBurger) {
            this._callOpenCloseBurger = (e: MouseEvent) => { this.onOpenCloseBurger(e) };
            this.iconeBurger.nativeElement.addEventListener('click', this._callOpenCloseBurger);
            this._subToogleMenu = this._actionService.toggleBurgerMenu.subscribe((isOpen: boolean) => { this.burgerMenuStateChange(isOpen) });
        } else {
            this._callOpenCloseBurger = (e: MouseEvent) => { this.onUrlBack(e) };
            this.iconeBurger.nativeElement.addEventListener('click', this._callOpenCloseBurger);
        }
    }

    public ngOnDestroy(): void {
        if (this._callOpenCloseBurger) {
            this.iconeSearch.nativeElement.removeEventListener('click', this._callOpenCloseSearch);
        }

        if (this._callOpenCloseSearch) {
            this.iconeBurger.nativeElement.removeEventListener('click', this._callOpenCloseBurger);
        }

        if (this._callClickSearInput) {
            this.searchContent.nativeElement.removeEventListener('click', this._callClickSearInput);
        }

        if (this._callDocumentClick) {
            document.removeEventListener('click', this._callDocumentClick);
        }

        if (this._callKeyboardUp) {
            document.removeEventListener('keyup', this._callKeyboardUp);
        }

        if (this._subToogleMenu) {
            this._subToogleMenu.unsubscribe();
        }
    }

    private onOpenCloseBurger(e: MouseEvent): void {
        this._actionService.toggleBurgerMenu.emit(this._isBurgerOpen);
    }

    private burgerMenuStateChange(isOpen: boolean): void {
        this._isBurgerOpen = !isOpen;
    }

    private onOpenCloseSearch(e: MouseEvent): void {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        this._isSearch = !this._isSearch;
        if (this._isSearch) {
            this.searchContent.nativeElement.style.display = 'block';
            this.createTweenSearch(1, 0);
        } else {
            this.emitSearch();
        }
    }

    private createTweenSearch(paramOpacitySearch: number, paramOpacityTitle: number): void {
        this._tweenService.updateTween.emit();

        let styleContentSearch: CSSStyleDeclaration = window.getComputedStyle(this.searchContent.nativeElement);
        let styleContentTitle: CSSStyleDeclaration = window.getComputedStyle(this.titleContent.nativeElement);
        let initialObject: any = { opacitySearch: styleContentSearch.opacity, opacityTitle: styleContentTitle.opacity };
        let targetObject: any = { opacitySearch: paramOpacitySearch, opacityTitle: paramOpacityTitle };
        let tween: TWEEN.Tween<any> = new TWEEN.Tween(initialObject);
        tween.to(targetObject, 450);
        tween.onUpdate((updateObject: any) => this.updateTweenSearch(updateObject));
        tween.onComplete(() => this.completeTweenSearch());
        tween.easing(TWEEN.Easing.Circular.Out);
        tween.start();
    }

    private updateTweenSearch(updateObject: any): void {
        this.searchContent.nativeElement.style.opacity = updateObject.opacitySearch;
        this.titleContent.nativeElement.style.opacity = updateObject.opacityTitle;
    }

    private completeTweenSearch(): void {
        this._tweenService.completedTween.emit();

        if (!this._isSearch) {
            this.searchContent.nativeElement.style.display = 'none';
        } else {
            this.searchContent.nativeElement.focus();

            this.searchContent.nativeElement.addEventListener('click', this._callClickSearInput);
            document.addEventListener('keyup', this._callKeyboardUp);
            document.addEventListener('click', this._callDocumentClick);
        }
    }

    private onDocumentClick(e: MouseEvent): void {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        this._isSearch = !this._isSearch;
        if (!this._isSearch) {
            this.searchContent.nativeElement.removeEventListener('click', this._callClickSearInput);
            document.removeEventListener('keyup', this._callKeyboardUp);
            document.removeEventListener('click', this._callDocumentClick);

            this.createTweenSearch(0, 1);
        }
    }

    private onSearchInputClick(e: MouseEvent): void {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
    }

    private onSendSearch(e: KeyboardEvent): void {
        if (e.keyCode == 13) {
            this.emitSearch();
        }
    }

    private emitSearch(): void {
        let searchValue: string = this.searchContent.nativeElement.value.trim();
        if (searchValue != "") {
            this._routeService.routeStartedChange.emit(RouteService.ROUTE_SEARCH_RESULT + "/" + searchValue);
        }
    }

    private onUrlBack(e: MouseEvent): void {
        window.history.back();
    }

}