export class CategoryTitleEntity{

    private _id:number;
    private _title:string;

    public get id():number{
        return this._id;
    }

    public get title():string{
        return this._title;
    }

    constructor(params:any){
        this._id = params.id;
        this._title = params.title;
    }

}