import { Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { QuestionEntity } from 'src/app/entities/question.entity';
import { ActionService } from 'src/app/service/action.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tabContent-root',
    templateUrl: './tabContent.component.html',
    styleUrls: ['./tabContent.component.scss']
})
export class TabContentComponent implements OnInit, AfterViewInit, OnDestroy{

    @Input() listQuestions:Array<QuestionEntity>;
    @Input() idList:number;
    @ViewChild('listRootDisplay') listRootDisplay:ElementRef;

    private _subTabClick:Subscription;

    constructor(private _actionService:ActionService, private _elRef:ElementRef){

    }

    public ngOnInit():void{
        this._subTabClick = this._actionService.tabClickButton.subscribe((id:number) => { this.clickHandler(id)});
    }

    public ngAfterViewInit():void{
        this.setDisplayTab(0);
    }

    public ngOnDestroy():void{
        this._subTabClick.unsubscribe();
    }

    private clickHandler(id:number):void{
        this.setDisplayTab(id);
    }

    private setDisplayTab(idTarget:number):void{
        this._elRef.nativeElement.parentElement.style.display = this.idList == idTarget ? "block" : "none";
    }

}