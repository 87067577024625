import { Component, Input, OnInit } from "@angular/core";
import { TagEntity } from 'src/app/entities/tag.entity';

@Component({
    selector: "tag-root",
    templateUrl: "./tag.component.html",
    styleUrls: ["./tag.component.scss"]
})
export class TagComponent implements OnInit{

    @Input() tagEntity:TagEntity;

    private _name:string;

    public get name():string{
        return this._name;
    }

    constructor(){

    }

    public ngOnInit():void{
        this._name = this.tagEntity.name;
    }

}