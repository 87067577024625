import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../core/constants/app-settings';
import { Device } from '../entities/device.entity';
import { Application } from '../page/models/application';
import { LocaleService } from './locale.service';
import { OrPushSubscription } from '../page/models/orPushSubscription';

@Injectable({
    providedIn: "root"
})
export class RequestService {
    private _headers: HttpHeaders;
    constructor(private _httpClient: HttpClient, private _localService: LocaleService) {
        const contentType = 'application/json';
        this._headers = new HttpHeaders().set('Content-Type', contentType).set('Accept', contentType);
    }

    public login(params: any): Observable<any> {
        return this._httpClient.post(environment.rootApi + AppSettings.LOGIN_PATH, params, { observe: 'response' });
    }

    public registerDevice(params: Device): Observable<any> {
        return this._httpClient.post(environment.rootApi + AppSettings.DEVICE_REGISTER_PATH, params, { headers: this._headers, observe: 'response' });
    }

    public user(): Observable<any> {
        return this._httpClient.get(environment.rootApi + AppSettings.USER_PATH, { headers: this._headers });
    }

    public getAppList(): Observable<Application[]> {
        return this._httpClient.get<Application[]>(environment.rootApi + AppSettings.APP_LIST_PATH, { headers: this._headers });
    }

    public getAppListTest(): Observable<Application[]> {
        return this._httpClient.get<Application[]>(environment.rootApi + AppSettings.TEST_APP_LIST_PATH, { headers: this._headers });
    }

    public getFicheApp(idApp: number): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.APP_SHEET_PATH + idApp.toString(), { headers: this._headers });
    }

    public getAppLogo(idApp: number): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.APP_SHEET_PATH + '?' + idApp.toString());
    }

    public sendSearch(paramSearch: string): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.SEARCH_PATH + '?' + paramSearch);
    }

    public getListImgCarouselPageList(): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.CAROUSEL_IMG_LIST_PATH, { headers: this._headers });
    }

    public subscribePush(sub: OrPushSubscription) {
        return this._httpClient.post(environment.rootApi + AppSettings.SUBSCRIPTION_PATH, sub, { headers: this._headers });
    }

    /**
     * OPE : remplacé par getFicheApp
     * @param idApp 
     * @returns 
     */
    public getListImgCarouselPageFiche(idApp: string): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.CAROUSEL_IMG_SHEET_PATH + '?' + idApp);
    }

    public getAboutApp(idApp: string): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.ABOUT_CONTENT_PATH + '?' + idApp);
    }

    public getFaqContent(): Observable<Object> {
        return this._httpClient.get(environment.rootApi + AppSettings.FAQ_CONTENT_PATH + '?' + this._localService.locale);
    }

}