import { Directive, OnInit, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[my-formControl]'
})
export class NativeElementInjectorDirective implements OnInit{

    constructor(private _elRef:ElementRef, private _control:NgControl){

    }

    public ngOnInit():void{
        (this._control.control as any).nativeElement = this._elRef.nativeElement;
    }

}