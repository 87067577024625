
export class Device {
    uuid: string;
    platform: string;
    deviceCategory: string;
    model: string;
    osVersion: string;
    userLocale: string;
    appVersion: string;
    registerId:string;
}