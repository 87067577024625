import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class TweenService{

    public static COUNT_USER:number = 0;

    public updateTween:EventEmitter<any>;
    public completedTween:EventEmitter<any>;

    constructor(){
        this.updateTween = new EventEmitter<any>();
        this.completedTween = new EventEmitter<any>();
    }

}