<div or-fiche>
    <header>
        <or-header [_isWithBurger]=false [_isWithSearch]=false>
        </or-header>
    </header>
    <section>
        <article>
    <div class="row">
        <div id="app-title">
            <div>
                <img src="{{ iconeAppUrl }}" alt="" />
                
            </div>
            <div>
                <h2>{{ appName }}</h2>
                <p>{{(appDescription?.length>35)? (appDescription | slice:0:35)+'...':(appDescription) }}</p>
            </div>
        </div>
    </div>
    <div class="row">
    <div or-app-component>
        <div>
            <div *ngFor="let appEntity of listAppTest" class="item_content">
                <div class="block_left">
                    <h5>Version {{appEntity.version}}</h5>
                    <p>{{appEntity.infoTest}}</p>
                </div>
                <div id="app-1" class="block_right">
                    <div>
                        <a target="_blank" (click)="showDialog(appEntity)">
                            <div>
                                <p>
                                    {{ 'appComponent.download' | translate }}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>  
            </div>
        </div>
        </div>
    </div>
    </article>
    </section>


<!--      <section>
        <article>
            <div class="row">
                <div id="app-title">
                    <div>
                        <img src="{{ iconeAppUrl }}" alt="" />
                    </div>
                    <div>
                        <h2>{{ appName }}</h2>
                        <p>{{ appDescription }}</p>
                    </div>
                </div>
            </div>
            <div class="row"></div>
            <div class="row"></div>
            <div or-app-component>
                <div>
                    <div *ngFor="let appEntity of listAppTest">
                        <div>
                        <h5>Version {{appEntity.version}}</h5>
                        <p>{{appEntity.infoTest}}</p>
                        </div>
                        <div id="app-1">
                            <div>
                                <a href="{{ appLink }}" target="_blank">
                                    <div>
                                        <p>
                                            {{ 'appComponent.download' | translate }}
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

        </article>
    </section>  -->
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>