export class CarouselImgEntity {

    public static NUM_MIN_IMG: number = 2;

    private _index: number;
    private _idApp: number;
    private _nameApp: string;
    private _urlImg: string;
    private _totalImg: number;
    private _offsetX: number;

    public get index(): number {
        return this._index;
    }

    public get idApp(): number {
        return this._idApp;
    }

    public get nameApp(): string {
        return this._nameApp;
    }

    public get urlImg(): string {
        return this._urlImg;
    }

    public get totalImg(): number {
        return this._totalImg;
    }

    public get offsetX(): number {
        return this._offsetX;
    }

    constructor(pIndex: number, pIdApp: number, pNameApp: string, pURL: string, pTotalIMG: number, pOffsetX: number) {
        this._index = pIndex;
        this._idApp = pIdApp;
        this._nameApp = pNameApp;
        this._urlImg = pURL;
        this._totalImg = pTotalIMG;
        this._offsetX = pOffsetX;
    }

}