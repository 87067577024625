<div or-nav>
    <div class="row">
        <div class="col-2">
            <img src="{{ urlImgAvatar }}" width="71" alt="avatar" />
            <div>
                <p>{{ name }}</p>
            </div>
            <div>
                <p>{{ lastname }}</p>
            </div>
        </div>
        <div class="col-10">
            <img #btReturn src="{{ urlIconBack }}" width="30" class="float-right"/>
        </div>
    </div>
    <div class="row">
        <div>
            <ul>
                <!--
                <li #btDiscover>
                    <div>
                        <div>
                            <div>
                                <img src="{{ urlImgDiscover }}" alt="discover" />
                            </div>
                        </div>
                        <div>
                            <p>{{ 'menu.discover' | translate }}</p>
                        </div>
                        <div>
                            <img src="./assets/img/list_app/chevron-burger.svg" alt="stripe" />
                        </div>
                    </div>
                </li>-->
                
                <li #btTest>
                    <div>
                        <div>
                            <div>
                                <img src="{{ urlImgTest }}" alt="test" />
                            </div>
                        </div>
                        <div>
                            <p>{{ 'menu.test' | translate }}</p>
                        </div>
                        <div>
                            <img src="./assets/img/list_app/chevron-burger.svg" alt="stripe" />
                        </div>
                    </div>
                </li>
                <li id="menu-content-local" #contentLocale>
                    <div #btToggleLocale>
                        <div>
                            <div>
                                <img src="{{ urlImgFlag }}" alt="lang" />
                            </div>
                        </div>
                        <div>
                            <p>{{ nameLocale }}</p>
                        </div>
                        <div>
                            <img #chevronLocale src="./assets/img/list_app/chevron-burger.svg" alt="stripe" />
                        </div>
                    </div>
                    <div #listLocale>
                        <ul>
                            <li>
                                <div #btEn_en>
                                    <div>
                                        <img #cocheEn src="./assets/img/login_app/coche.svg" alt="check" />
                                    </div>
                                    <div>
                                        <img src="./assets/img/login_app/en_flag.svg" alt="english" />
                                    </div>
                                    <div>
                                        <p>
                                            {{ nameLocaleEn }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div #btFr_fr>
                                    <div>
                                        <img #cocheFr src="./assets/img/login_app/coche.svg" alt="check" />
                                    </div>
                                    <div>
                                        <img src="./assets/img/login_app/fr_flag.svg" alt="french" />
                                    </div>
                                    <div>
                                        <p>
                                            {{ nameLocaleFr }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li #btContact>
                    <div>
                        <div>
                            <div>
                                <img src="./assets/img/list_app/icon-burger3.svg" alt="contact" />
                            </div>
                        </div>
                        <div>
                            <p>{{ 'menu.contact' | translate }}</p>
                        </div>
                        <div>
                            <img src="./assets/img/list_app/chevron-burger.svg" alt="stripe" />
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <div>
                                <img src="./assets/img/list_app/icon-deconnect.svg" alt="logout" />
                            </div>
                        </div>
                        <div>
                            <p (click)="showDialog()">{{ 'menu.logout' | translate }}</p>
                            <app-dialog #appDialog></app-dialog>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div id="poltique">
        <p  (click)="goPolitiqueConfid()">{{ 'menu.politique' | translate }}</p>
    </div> 
</div>


   