import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { CategoryTitleEntity } from 'src/app/entities/category-title.entity';
import { ActionService } from 'src/app/service/action.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tabTitle-root',
    templateUrl: './tabTitle.component.html',
    styleUrls: ['./tabTitle.component.scss']
})
export class TabTitleComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() titleEntity: CategoryTitleEntity;
    @ViewChild('btTab') btTab: ElementRef;

    private _callBtTabClick: Function;
    private _subTabClick: Subscription;
    private _title: string;
    private _isSelected: boolean;

    public get title(): string {
        return this._title;
    }

    public get isSelected(): boolean {
        return this._isSelected;
    }

    constructor(private _actionService: ActionService) {

    }

    public ngOnInit(): void {
        this._title = this.titleEntity.title;
        this._callBtTabClick = (e: MouseEvent) => { this.onTabClickHandler(e) };
        this._isSelected = this.titleEntity.id == 0;
    }

    public ngAfterViewInit(): void {
        this.btTab.nativeElement.addEventListener('click', this._callBtTabClick);
        this._subTabClick = this._actionService.tabClickButton.subscribe((id: number) => { this.clickHandler(id) });
    }

    public ngOnDestroy(): void {
        this.btTab.nativeElement.removeEventListener('click', this._callBtTabClick);
        this._subTabClick.unsubscribe();
    }

    private onTabClickHandler(e: MouseEvent): void {
        this._actionService.tabClickButton.emit(this.titleEntity.id);
    }

    private clickHandler(id: number): void {
        this._isSelected = this.titleEntity.id == id;
    }

}