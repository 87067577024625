<div or-about>
    <header>
        <or-header [_isWithBurger]=false [_isWithSearch]=true>
        </or-header>
    </header>
    <section>
        <article>
            <div class="row">
                <div id="titleTextAbout">
                    <h2>{{ 'about.pageTitle' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <div #contentTextAbout id="contentTextAbout">

                </div>
            </div>
        </article>
    </section>
    <footer>
        <or-footer-app>
        </or-footer-app>
    </footer>
</div>